<template>
  <div>
    <MenuB
      as="div"
      class="inline-block relative text-left"
    >
      <div>
        <MenuButton
          class="inline-flex justify-center py-2 px-4 w-full text-sm font-medium text-white bg-black bg-opacity-20 hover:bg-opacity-30 rounded-md focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus:outline-none"
        >
          <img
            v-if="user && user.picture"
            class="w-10 h-10 rounded-full"
            :src="user.picture"
            alt="Rounded avatar"
          >
        </MenuButton>
      </div>

      <transition
        enter-active-class="transition duration-100 ease-out"
        enter-from-class="transform scale-95 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="transition duration-75 ease-in"
        leave-from-class="transform scale-100 opacity-100"
        leave-to-class="transform scale-95 opacity-0"
      >
        <MenuItems
          class="absolute right-0 mt-2 w-56 bg-white rounded-md divide-y divide-gray-100 ring-1 ring-black ring-opacity-5 shadow-lg origin-top-right focus:outline-none"
        >
          <div class="py-1 px-1">
            <MenuItem v-slot="{ active }">
              <button
                :class="[
                  active ? 'text-gray-500' : 'text-gray-900',
                  'group flex w-full items-center rounded-md px-2 py-2 text-sm',
                ]"
                @click="logout()"
              >
                Logout
              </button>
            </MenuItem>
          </div>
        </MenuItems>
      </transition>
    </MenuB>
  </div>
</template>

<script>
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";
import UserStore from "common/UserStore.js";

export default {
  name: "CoreUserBadge",
  setup() {
    const userStore = UserStore.inject();
    return {
      user: userStore.user.value,
      id: userStore.idTokenClaims.value,
      logout: () => {
        userStore.logout({
          returnTo: `${window.location.origin}`,
        });
      },
    };
  },
  components: {
    MenuB: Menu,
    MenuButton,
    MenuItems,
    MenuItem,
  },
};
</script>
