import { FIELD_TYPES, Model } from "shared/Model.js";

/**
 * @property {String} name
 * @property {Number} paid
 * @property {Number} unpaid
 * @property {Number} billed
 * @property {Number} percentChange
 */
export class CategoryModel extends Model {
  get fields() {
    return [{
      name: "name",
    }, {
      name: "paid",
      type: FIELD_TYPES.DECIMAL,
    }, {
      name: "billed",
      type: FIELD_TYPES.DECIMAL,
    }, {
      name: "percentChange",
      type: FIELD_TYPES.DECIMAL,
      default: 0,
    }];
  }

  get unpaid() {
    return this.billed - this.paid;
  }
}
