<template>
  <CoreCard
    title="Revenue by Account Breakdown"
    body-cls="grid grid-cols-5 flex-1"
    :has-bottom-pad="false"
  >
    <template #body>
      <ChartCategoryRevenue
        class="col-span-2"
        :data="options.chartCategoriesData"
        :chart-options="options.chartCategoriesOptions"
      />
      <TableCategories
        class="col-span-3"
        :data="options.tableCategoriesData"
        :summary-headers="options.tableCategoriesSummaryHeaders"
      />
    </template>
  </CoreCard>
</template>

<script>
import { CoreCard } from "@purepm/core-ui";
import TableCategories, { TableCategoriesHeaders } from "ui/components/TableCategories.vue";
import ChartCategoryRevenue from "ui/components/ChartCategoryRevenue.vue";
import { inject, computed } from "vue";
import { useDefaultPieChart } from "ui/composables/chart.js";
import BadgePercentChange from "ui/components/BadgePercentChange.vue";

export default {
  name: "ContainerCategories",
  components: {
    ChartCategoryRevenue,
    TableCategories,
    CoreCard,
  },
  setup() {
    const revenueRecord = inject("revenueRecord");
    const options = computed(() => {
      const chartCategoriesData = [];
      const chartCategoriesLabels = [];
      const chartChangeData = [];
      const chartChangeLabels = [];
      const { summary = {}, categories } = revenueRecord.value || {};
      const total = categories?.length || 0;
      const tableCategoriesData = [];
      const billedTotal = categories?.sum("billed") || 0;
      const paidTotal = categories?.sum("paid") || 0;
      const unpaidTotal = billedTotal - paidTotal;
      const tableCategoriesSummaryHeaders = [{
        value: `Total: ${total}`,
      }, {
        value: billedTotal?.toCurrency(),
      }, {
        component: BadgePercentChange,
        props: {
          value: summary.revenueTotalChange || 0,
        },
      }, {
        value: paidTotal?.toCurrency(),
      }, {
        value: unpaidTotal?.toCurrency(),
      }];
      categories?.forEach((record) => {
        const { paid, name } = record;
        chartCategoriesData.push(paid);
        chartCategoriesLabels.push(`${name}: ${paid.toCurrency()}`);
        chartChangeData.push(record.percentChange);
        chartChangeLabels.push(name);
        tableCategoriesData.push(TableCategoriesHeaders.map(({
          key, formatter, component,
        }) => {
          const value = record[key] || 0;
          if (component) {
            return {
              key,
              component,
              content: value,
              props: {
                value,
              },
            };
          }
          return {
            key,
            content: formatter ? formatter(value) : value,
          };
        }));
      });

      return {
        chartCategoriesData,
        tableCategoriesSummaryHeaders,
        tableCategoriesData,
        chartCategoriesOptions: useDefaultPieChart({
          labels: chartCategoriesLabels,
          chart: {
            type: "donut",
          },
          legend: {
            show: false,
          },
        }),
        chartChangeData: [{
          data: chartChangeData,
          name: "Categories",
        }],
      };
    });
    return {
      options,
    };
  },
};
</script>
