import { EnumHelper } from "shared/EnumHelper.js";

const quartersDisplay = ["Q1 (Jan - Mar)", "Q2 (Apr - June)", "Q3 (Jul - Sep)", "Q4 (Oct - Dec)"];

class QuarterEnum extends EnumHelper {
  constructor() {
    const quarters = [];
    for (let i = 1; i <= 4; i++) {
      quarters.push({
        [`Q${i}`]: i,
      });
    }
    super(quarters);
  }

  get options() {
    return this.keys.map((key, i) => {
      return {
        value: this[key],
        label: quartersDisplay[i],
      };
    });
  }
}

/**
 * @property {number} Q1
 * @property {number} Q2
 * @property {number} Q3
 * @property {number} Q4
 */
export const Quarter = new QuarterEnum();
