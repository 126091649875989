/**
 * The purpose of this class is to mold our enum-like objects into something we can easily access and maintain.  It
 * has the ability to create the keys and values for you, if you do new EnumHelper(flatArrayToUse).  e.g.
 *
 * const flatArrayToUse = ["indigo-200", "indigo-300"];
 * const tailwindColors = new EnumHelper(flatArrayToUse);
 * // Gives us
 * {
 *   INDIGO_200: "indigo-200",
 *   INDIGO_300: "indigo-300"
 *   // Also contains methods that are defined in the class
 * }
 *
 * The one caveat is if you pass in a value that starts with a number, it'll be turned into a string, and you'll have
 * to access it through bracket notation, so it's recommended to have your keys start with a letter... e.g.
 *
 * const numbers = new EnumHelper([20, 21])
 * // Gives us
 * {
 *   "20": 20,
 *   "21": 21
 * }
 * numbers["20"] // this is how you'd access this property
 */
class EnumHelper {
  constructor(config) {
    if (Array.isArray(config)) {
      config.forEach((item) => {
        // If the dev has supplied an object, we'll consider this to be the key-value, and just push this onto our object
        if (item.constructor === Object) {
          Object.assign(this, item);
        } else {
          this.addValue(item);
        }
      });
    }
  }

  get options() {
    return this.keys.map((key) => {
      return {
        value: this[key],
        label: key,
      };
    });
  }

  get count() {
    return this.keys.length;
  }

  /**
   * Convenience method for returning all of the keys within this instance
   * @returns {String[]}
   */
  get keys() {
    return Object.keys(this).filter((key) => !key.includes("_KEY"));
  }

  /**
   * Convenience method for returning all of the values within this instance
   * @returns {String[]|Number[]}
   */
  get values() {
    return Object.values(this);
  }

  /**
   * This methods converts our value to how we represent keys in this class... this is because we want to massage our
   * values, so we can easily reference the keys instead of having to use brackets and quotes
   * @param {String} value
   * @returns {String}
   */
  valueToKey(value) {
    // If our string starts with an uppercase, we erroneously add an underscore, which is why we do the replace at the very end of this
    return String(value).replace(/(?=[A-Z][a-z0-9])|-|\s/g, "_").toUpperCase().replace(/^_/, "");
  }

  /**
   * Method that takes care of converting the value you want to add to the appropriate key and putting it in the instance
   * @param {String|Number} value
   */
  addValue(value) {
    const key = this.valueToKey(value);
    this[key] = value;
    this[`${key}_KEY`] = key;
  }

  /**
   * Method for determining if this value exists within the instance
   * @param {String|Number} value
   * @returns {Boolean}
   */
  contains(value) {
    const values = this.values;
    return values.indexOf(value) !== -1;
  }

  /**
   * This method will output the class's properties in a commented fashion, so we can put next to the instance of the
   * class for ease of intellisense.
   *
   * NOTE: This method should not be used for any other purposes... it's merely a helper for documentation
   */
  toClassDescription() {
    return "/**\n" + Object.keys(this).map((item) => {
      return `* @property {${(typeof this[item])}} ${item}`;
    }).join("\n") + "\n*/";
  }
}

export {
  EnumHelper,
};
