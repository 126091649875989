<template>
  <div class="bg-indigo-50">
    <BasicHeader class="col-span-12" />
    <router-view />
  </div>
</template>

<script>
import FormStore from "store/FormStore.js";
import UserStore from "common/UserStore.js";
import CompanyStore from "store/CompanyStore.js";
import DashboardStore from "store/DashboardStore.js";
import BasicHeader from "components/BasicHeader.vue";

export default {
  name: "App",
  components: {
    BasicHeader,
  },
  setup() {
    UserStore.init();
    FormStore.init();
    CompanyStore.init();
    DashboardStore.init();
  },
};
</script>
<style>
#app {
  font-family: var(--default-font-family);
}
</style>
