import { computed, inject, provide } from "vue";
import { useAuth0 } from "@auth0/auth0-vue";
import axios from "axios";

const UserStore = {
  init: () => {
    const {
      getAccessTokenSilently,
      getAccessTokenWithPopup,
      isAuthenticated,
      idTokenClaims,
      user,
      loginWithRedirect,
      logout,
    } = useAuth0();
    const onRequestIntercept = async function(config) {
      let token;
      try {
        token = await getAccessTokenSilently({ });
      } catch (err) {
        console.warn(err);
        token = await getAccessTokenWithPopup({ });
      }
      if (token) {
        if (config.headers == null) {
          config.headers = {};
        }
        config.headers["Authorization"] = `Bearer ${token}`;
      } else {
        throw (new Error("Could not get valid token"));
      }
      return config;
    };
    const userToken = async () => {
      return await getAccessTokenSilently({ });
    };
    const isAuthenticatedComp = computed(() => isAuthenticated);
    const userComp = computed(() => user);
    const idTokenClaimsComp = computed(() => idTokenClaims);
    axios.interceptors.request.use(
      onRequestIntercept,
      (error) => Promise.reject(error),
    );
    axios.interceptors.response.use((response) => response, (response) => {
      if (response.response.status === 401 || response.response.status === 403) {
        window.location.href = "/#/magic-word";
      }
      return Promise.reject(response);
    });
    provide("isAuthenticated", isAuthenticatedComp);
    provide("user", userComp);
    provide("idTokenClaims", idTokenClaimsComp);
    provide("logout", logout);
    provide("loginWithRedirect", loginWithRedirect);
    provide("userToken", userToken);
  },
  inject: () => ({
    isAuthenticated: inject("isAuthenticated"),
    user: inject("user"),
    idTokenClaims: inject("idTokenClaims"),
    logout: inject("logout"),
    loginWithRedirect: inject("loginWithRedirect"),
    userToken: inject("userToken"),
  }),
};

export default UserStore;
