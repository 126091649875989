/**
 * The purpose of this file is to contain all of our custom TailwindColors in an exportable fashion, so we can use it
 * as an import in tailwind.config.mjs as well as something we can consume in util.js, so we can make constants.  The
 * reason it's separated from tailwind.config.mjs is because of the battle between CJS and ESM.
 */
export default {
  "blue": {
    overlay: "#2A79D6",
    900: "#1E3A8A",
    800: "#1E40AF",
    700: "#1D4ED8",
    600: "#2563EB",
    500: "#4593FF",
    400: "#6BB8FE",
    300: "#7DD1FF",
    200: "#A3D7FB",
    100: "#CDE6F8",
    50: "#E1EDF6",
  },
  "pink": {
    900: "#831843",
    800: "#9D174D",
    700: "#BE185D",
    600: "#DB2777",
    500: "#EC4899",
    400: "#F472B6",
    300: "#F9A8D4",
    200: "#FBCFE8",
    100: "#FCE7F3",
    50: "#FDF2F8",
  },
  "rose": {
    900: "#881337",
    800: "#9F1239",
    700: "#A26674",
    600: "#C56A7E",
    500: "#F27692",
    400: "#FB7185",
    300: "#FDA4AF",
    200: "#FECDD3",
    100: "#FFE4E6",
    50: "#FFF1F2",
  },
  "light-blue": {
    900: "#0C4A6E",
    800: "#075985",
    700: "#0369A1",
    600: "#0284C7",
    500: "#0EA5E9",
    400: "#38BDF8",
    300: "#7DD3FC",
    200: "#BAE6FD",
    100: "#E0F2FE",
    50: "#F0F9FF",
  },
  "indigo": {
    900: "#1B1B30",
    800: "#013368",
    750: "#0050A5",
    700: "#2551B6",
    600: "#2A6CF9",
    500: "#4593FF",
    400: "#6BB8FE",
    300: "#9BCAF6",
    200: "#BBDEFF",
    100: "#CDE6F8",
    50: "#E8EEF2",
  },
  "purple": {
    900: "#381F36",
    800: "#4D304A",
    700: "#6E4369",
    600: "#82517D",
    500: "#A579A9",
    400: "#B992D8",
    300: "#C4B5FD",
    200: "#DDD6FE",
    100: "#EDE9FE",
    50: "#F5F3FF",
  },
  "cyan": {
    900: "#164E63",
    800: "#155E75",
    700: "#0E7490",
    600: "#0891B2",
    500: "#06B6D4",
    400: "#22D3EE",
    300: "#89DDE8",
    200: "#A5F3FC",
    100: "#CFFAFE",
    50: "#ECFEFF",
  },
  "teal": {
    900: "#134E4A",
    800: "#115E59",
    700: "#0F766E",
    600: "#0D9488",
    500: "#14B8A6",
    400: "#2DD4BF",
    300: "#5EEAD4",
    200: "#99F6E4",
    100: "#CCFBF1",
    50: "#F0FDFA",
  },
  "green": {
    900: "#064E3B",
    800: "#065F46",
    700: "#047857",
    600: "#059669",
    500: "#10B981",
    400: "#77E3A9",
    300: "#6EE7B7",
    200: "#A7F3D0",
    100: "#D1FAE5",
    50: "#ECFDF5",
  },
  "red": {
    50: "#FFF7ED",
    100: "#FFEDD5",
    200: "#FED7AA",
    300: "#FFB262",
    400: "#FF8E31",
    500: "#FF6422",
    600: "#FF460B",
    700: "#C2410C",
    800: "#9A3412",
    900: "#742104",
  },
  "yellow": {
    900: "#664833",
    800: "#8D6747",
    700: "#B4855A",
    600: "#CD9947",
    500: "#E6AD35",
    400: "#FFC122",
    300: "#FFD15F",
    200: "#FFE29B",
    100: "#FFF2D8",
    50: "#FFF9EC",
  },
  "orange": {
    50: "#FFF7ED",
    100: "#FFEDD5",
    200: "#FED7AA",
    300: "#FFB262",
    400: "#FF8E31",
    500: "#FF6422",
    600: "#FF460B",
    700: "#C2410C",
    800: "#9A3412",
    900: "#742104",
  },
  "gray": {
    50: "#F9FAFB",
    75: "#F6F7F8",
    100: "#EBEEEF",
    200: "#EAEAEA",
    300: "#DDE1E7",
    400: "#CFD8E3",
    500: "#939EB1",
    600: "#748095",
    700: "#636C82",
    800: "#343B53",
    900: "#292E3F",
    1000: "#F4F8FD",
  },
  "sky": {
    50: "#F0F9FF",
    100: "#E0F2FE",
    200: "#BAE6FD",
    300: "#7DD3FC",
    400: "#38BDF8",
    500: "#0EA5E9",
    600: "#0284C7",
    700: "#0369A1",
    800: "#075985",
    900: "#0C4A6E",
  },
};
