<template>
  <div class="flex flex-col border-x">
    <FRPDataTable
      hide-pagination
      class="flex-1 data-table-frp"
      :headers="TableCategoriesHeaders"
      :rows="data"
      :page-size="1000"
      :initial-sort="{field: 'percentChange', direction: 'desc'}"
      :footer-rows="summaryHeaders"
      @sort="onSortChange"
    />
  </div>
</template>

<script>
import FRPDataTable from "ui/components/FRPDataTable.vue";
import { inject } from "vue";
import BadgePercentChange from "ui/components/BadgePercentChange.vue";
import { commonSort } from "shared/utilities.js";

export const TableCategoriesHeaders = [{
  key: "name",
  value: "Account",
  sortable: true,
}, {
  key: "billed",
  value: "Billed",
  sortable: true,
  formatter(value) {
    return value.toCurrency();
  },
}, {
  key: "percentChange",
  value: "Change",
  sortable: true,
  component: BadgePercentChange,
}, {
  key: "paid",
  value: "Paid",
  sortable: true,
  formatter(value) {
    return value.toCurrency();
  },
}, {
  key: "unpaid",
  value: "Unpaid",
  sortable: true,
  formatter(value) {
    return value.toCurrency();
  },
}];

export default {
  name: "TableCategories",
  components: {
    FRPDataTable,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    summaryHeaders: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const revenueRecord = inject("revenueRecord");
    return {
      TableCategoriesHeaders,
      onSortChange({ field, direction }) {
        const { categories } = revenueRecord.value || {};
        categories?.sort((lhs, rhs) => commonSort(lhs[field], rhs[field], direction === "asc" ? 1 : -1));
      },
    };
  },
};
</script>
