<template>
  <CoreDataTable
    class="w-full"
    :headers="headers"
    :rows="rows"
    hide-pagination
    :page-size="100"
  />
</template>

<script>
import { CoreDataTable } from "@purepm/core-ui";
import DashboardStore from "ui/store/DashboardStore.js";
import { computed } from "vue";

export default {
  name: "TableDashboardRevenueHistory",
  components: {
    CoreDataTable,
  },

  setup() {
    const dashboardStore = DashboardStore.inject();
    const rows = dashboardStore.getRows;
    const year = dashboardStore.getDate;
    const headers = computed(() => {
      return [
        {
          key: "category",
          value: `${year.value}`,
        }, {
          key: 0,
          value: "Jan",
        }, {
          key: 1,
          value: "Feb",
        }, {
          key: 2,
          value: "Mar",
        }, {
          key: 3,
          value: "Apr",
        }, {
          key: 4,
          value: "May",
        }, {
          key: 5,
          value: "Jun",
        }, {
          key: 6,
          value: "Jul",
        }, {
          key: 7,
          value: "Aug",
        }, {
          key: 8,
          value: "Sep",
        }, {
          key: 9,
          value: "Oct",
        }, {
          key: 10,
          value: "Nov",
        }, {
          key: 11,
          value: "Dec",
        },
      ];
    });

    return {
      headers,
      rows,
      year,
    };
  },

};
</script>

