<template>
  <div class="flex space-x-2">
    <CoreFieldSelectMulti
      v-model="company"
      class="w-64"
      field-label="Brand"
      placeholder="Select Brand"
      :options="companyOptions"
      :disabled="!isPreview"
      @update:modelValue="getSnapshot"
    />
    <CoreFieldSelectMulti
      v-model="year"
      class="w-36"
      field-label="Year"
      placeholder="Select Year"
      :options="yearOptions"
      :disabled="!isPreview"
      @update:modelValue="getSnapshot"
    />
    <CoreFieldSelectMulti
      v-model="month"
      class="w-48"
      field-label="Month"
      placeholder="Select Month"
      :options="monthOptions"
      :disabled="!isPreview"
      @update:modelValue="getSnapshot"
    />
    <div
      v-if="isEditCapable"
      class="mt-6"
    >
      <div v-if="!isPreview">
        <CoreButton
          :text="'Cancel'"
          class="mr-2"
          version="link"
          @click="cancel"
        />
        <CoreButton
          :text="'Save'"
          :version="'primary'"
          @click="handleSubmit"
        />
      </div>
      <CoreButton
        v-if="isPreview"
        :version="applyButtonVersion"
        @click="toggle($event)"
      >
        <span>Edit</span>
      </CoreButton>
    </div>
  </div>
  <template v-if="notify">
    <!-- Global notification live region, render this permanently at the end of the document -->
    <div
      aria-live="assertive"
      class="flex fixed inset-0 items-end py-6 px-4 mt-8 pointer-events-none sm:items-start sm:p-6"
    >
      <div class="flex flex-col items-center space-y-4 w-full sm:items-end">
        <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
        <transition
          enter-active-class="transform ease-out duration-300 transition"
          enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
          leave-active-class="transition ease-in duration-100"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <div
            class="overflow-hidden w-full max-w-sm bg-white rounded-lg ring-1 ring-black shadow-lg pointer-events-auto ring-opacity-/5"
          >
            <div class="p-4">
              <div class="flex items-start">
                <div class="flex-1 pt-0.5 ml-3 w-0">
                  <p class="text-sm font-medium text-gray-900">
                    {{ submitStatus }}
                  </p>
                </div>
                <div class="flex ml-4 shrink-0">
                  <button
                    class="inline-flex text-gray-400 hover:text-gray-500 bg-white rounded-md focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:outline-none"
                    @click="handleClearNotification"
                  >
                    <span class="sr-only">Close</span>
                    <XIcon
                      class="w-5 h-5"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </template>
  <div
    v-show="cState.showOotModal"
    style="position:fixed;top:0;left:0;right:0;bottom:0;background:rgba(0,0,0,0.3);z-index:1000;"
  >
    <div style="padding:20px;width:500px;height:185px;position:absolute;top:0;left:0;right:0;bottom:0;background:white;display:block;margin:auto;border-radius:10px;">
      <span class="text-orange">
        Warning
      </span>
      <p class="mt-2">
        The time period for edits to this month has expired. Data entry outside of the allowable time window cannot be saved. Please contact <a href="mailto:cfodash@purepm.com">cfodash@purepm.co</a> for assistance in submitting edits.
      </p>
      <CoreButton
        style="margin-top:20px"
        @click="cState.showOotModal = false;"
      >
        <span>Okay</span>
      </CoreButton>
    </div>
  </div>
</template>

<script>
import { CoreFieldSelectMulti,
  CoreButton,
  EnumsButtonVersions } from "@purepm/core-ui";
import jwtDecode from "jwt-decode";
import FormStore from "store/FormStore.js";
import CompanyStore from "store/CompanyStore.js";
import { XIcon, PencilAltIcon } from "@heroicons/vue/solid";
import { computed, watch } from "vue";
import { isEmpty } from "shared/utilities.js";
import { ref, reactive } from "vue";
import UserStore from "ui/common/UserStore.js";

export default {
  name: "InputFormFilters",
  components: {
    CoreFieldSelectMulti,
    CoreButton,
    XIcon,
  },
  setup() {
    const userStore = UserStore.inject();
    const token = ref("");
    const getToken = async () => {
      const rawToken = await userStore.userToken();
      token.value = jwtDecode(rawToken);
    };
    getToken();
    const isEditCapable = computed(() => {
      if (token.value?.permissions?.includes("cfo:edit") || token.value?.permissions?.includes("cfo:edit:unlimited")) {
        return true;
      } else return false;
    });
    const icon = PencilAltIcon;
    const companyStore = CompanyStore.inject();
    const store = FormStore.inject();
    const notify = ref(false);
    const submitStatus = ref("");
    companyStore.fetchCompanies();
    const editionBlocked = ref(false);
    const handleApplyFilters = async () => {
      store.getMonthlySnapshot();
    };
    const yearOptions = computed({
      get: () => store.getYearOptions.value,
    });
    const monthOptions = computed({
      get: () => store.getMonthOptions.value,
    });
    const showEdit = computed({
      get: () => store.getShowEdit.value,
    });
    const cancel = () => {
      store.cancel();
    };
    const edit = () => {
      store.edit();
    };
    const toggle = () => {
      store.setIsPreview(false);
    };
    const company = computed({
      get: () => store.getSelectedCompany.value,
      set: store.updateSelectedCompany,
    });

    const year = computed({
      get: () => store.getSelectedYear.value,
      set: store.updateSelectedYear,
    });
    const month = computed({
      get: () => store.getSelectedMonth.value,
      set: store.updateSelectedMonth,
    });
    const isPreview = computed({
      get: () => store.getIsPreview.value,
    });
    const isLoading = computed({
      get: () => companyStore.isLoading.value,
    });
    const applyButtonVersion = computed(() => {
      let hasPermissionToEdit = false;
      const editableTimeframe = store.getEditableTimeframe.value;
      const { permissions } = token.value;
      // if now is within the editable timeframe and has edit permission, then we can edit
      if (editableTimeframe && permissions?.includes("cfo:edit")) {
        const now = new Date();
        const start = new Date(editableTimeframe.start);
        const end = new Date(editableTimeframe.end);
        if (now >= start && now <= end) {
          hasPermissionToEdit = true;
        }
      }
      if (permissions?.includes("cfo:edit:unlimited")) {
        hasPermissionToEdit = true;
      }
      const disable = isEmpty(company.value) || isEmpty(year.value) || isEmpty(month.value) || !isPreview.value || isLoading.value || !hasPermissionToEdit;
      return disable ? EnumsButtonVersions.DISABLED : EnumsButtonVersions.PRIMARY;
    });
    const disableSaveBtn = computed({
      get: () => store.getDisableSaveBtn.value,
    });

    const applySaveButtonVersion = computed(() => {
      return disableSaveBtn.value ? EnumsButtonVersions.DISABLED : EnumsButtonVersions.PRIMARY;
    });

    const companyOptions = computed({
      get: () => companyStore.getAllCompanies.value,
    });

    const cState = reactive({
      showOotModal: false,
      ootError: {},
    });

    async function handleSubmit() {
      try {
        store.formatFormData();
        const response = await store.submitForm(
          `${userStore.user.value.value.name}`,
        );
        if (
          response && (response.status === 201 || response.status === 204)
        ) {
          store.setBackupFormData();
          submitStatus.value = "Data Saved!";
          notify.value = true;
          await store.getMonthlySnapshot();
          store.setIsPreview(true);
          setTimeout(() => (notify.value = false), 2200);
        } else {
          submitStatus.value = "Could not save data!";
          notify.value = true;
        }
      } catch (err) {
        if (err.response.data.id === "oot") {
          cState.showOotModal = true;
          cState.ootError = err.response.data;
          cState.ootError.start = (new Date(err.response.data.start * 1000).toDateString());
          cState.ootError.end = (new Date(err.response.data.end * 1000).toDateString());
        } else {
          submitStatus.value = "Could not save data!";
          notify.value = true;
        }
      }
    }
    const handleClearNotification = () => {
      notify.value = !notify.value;
    };
    const getSnapshot = () => {
      // setTimeout allows for the year's clearing out of the selected month to occur before calling the method
      setTimeout(store.getMonthlySnapshot, 250);
    };
    watch(year, () => {
      month.value = null;
    });

    return {
      company,
      year,
      month,
      companyOptions,
      yearOptions,
      monthOptions,
      handleApplyFilters,
      isLoading,
      applyButtonVersion,
      isPreview,
      showEdit,
      cancel,
      edit,
      applySaveButtonVersion,
      toggle,
      editionBlocked,
      handleSubmit,
      notify,
      submitStatus,
      icon,
      handleClearNotification,
      getSnapshot,
      cState,
      isEditCapable,
    };
  },
}; </script>

