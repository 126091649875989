import { createApp } from "vue";
import "ui/index.css";
import App from "ui/App.vue";
import "shared/overrides/index.js";
import CoreUI from "@purepm/core-ui/dist/CoreUI.js";
import router from "./router";
import { createAuth0 } from "@auth0/auth0-vue";

const app = createApp(App);

const domains = [
  import.meta.env.VITE_LOCAL_DOMAIN,
  import.meta.env.VITE_DEV_DOMAIN,
  import.meta.env.VITE_STAGE_DOMAIN,
  import.meta.env.VITE_PROD_DOMAIN,
];
const configs = [
  {
    domain: import.meta.env.VITE_AUTH_ID_LOCAL,
    client_id: import.meta.env.VITE_CLIENT_ID_LOCAL,
    redirect_uri: import.meta.env.VITE_LOCAL_DOMAIN,
    useRefreshTokens: true,
    audience: import.meta.env.VITE_AUDIENCE_LOCAL,
    cacheLocation: "localstorage",
    scope: "view",
  },
  {
    domain: import.meta.env.VITE_AUTH_ID_DEV,
    client_id: import.meta.env.VITE_CLIENT_ID_DEV,
    redirect_uri: import.meta.env.VITE_DEV_DOMAIN,
    useRefreshTokens: true,
    audience: import.meta.env.VITE_AUDIENCE_DEV,
    cacheLocation: "localstorage",
    scope: "view",
  },
  {
    domain: import.meta.env.VITE_AUTH_ID_STAGE,
    client_id: import.meta.env.VITE_CLIENT_ID_STAGE,
    redirect_uri: import.meta.env.VITE_STAGE_DOMAIN,
    useRefreshTokens: true,
    audience: import.meta.env.VITE_AUDIENCE_STAGE,
    cacheLocation: "localstorage",
    scope: "view",
  },
  {
    domain: import.meta.env.VITE_AUTH_ID_PROD,
    client_id: import.meta.env.VITE_CLIENT_ID_PROD,
    useRefreshTokens: true,
    redirect_uri: import.meta.env.VITE_PROD_DOMAIN,
    audience: import.meta.env.VITE_AUDIENCE_PROD,
    cacheLocation: "localstorage",
    scope: "view",
  }];

const config = configs[domains.indexOf(window.location.origin)] || configs[0];
export default config;

app.config.unwrapInjectedRef = true;
app.use(CoreUI);
app.use(router);
app.use(
  // Audience is the default and can be set at the getToken level if they need to change
  createAuth0(config),
);
app.mount("#app");
