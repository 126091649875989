<template>
  <apexchart
    height="400"
    type="line"
    class="py-3 pr-3 bg-white rounded-bl-lg"
    :options="options.chartOptions"
    :series="options.data"
  />
</template>

<script>
import VueApexCharts from "vue3-apexcharts";
import { AggregateType } from "shared/enums/AggregateType.js";
import TailwindColors from "ui/TailwindColors.js";
import { createChartDateLabel } from "../../utilities/labelsHelper.js";

const titleKeys = AggregateType.options;
export default {
  name: "ChartTotalDoors",
  components: {
    apexchart: VueApexCharts,
  },
  inject: ["revenueRecord", "dataFilters"],
  computed: {
    options() {
      const totalDoorSeries = [];
      const labels = [];
      const { aggregateType = AggregateType.Monthly } = this.dataFilters || {};
      const chartTitle = titleKeys[aggregateType].label + " trend";
      this.revenueRecord?.summary.revenueHistory.forEach((period) => {
        const { doorsTotal, date } = period;
        totalDoorSeries.push(doorsTotal);
        createChartDateLabel(labels, aggregateType, date);
      });
      return {
        data: [
          {
            name: "Total Doors",
            type: "line",
            data: totalDoorSeries,
          },
        ],
        chartOptions: {
          chart: {
            type: "line",
            zoom: {
              enabled: false,
            },
            toolbar: {
              offsetX: -4,
              offsetY: 16,
              tools: {
                pan: false,
                selection: false,
                zoom: true,
                zoomout: false,
                reset: false,
                zoomin: false,
              },
            },
          },
          colors: [TailwindColors.blue["500"]],
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "straight",
          },
          title: {
            text: chartTitle,
            offsetX: 8,
            offsetY: 12,
            margin: 14,
            style: {
              fontFamily: "Roboto",
            },
          },
          tooltip: {
            enabled: true,
            style: {
              fontFamily: "Roboto",
            },
            x: {
              show: false,
            },
          },
          legend: {
            showForSingleSeries: true,
            labels: {
              useSeriesColors: true,
              fontSize: "10px",
            },
            fontFamily: "Roboto",
            position: "bottom",
            horizontalAlign: "left",
            itemMargin: {
              vertical: 20,
            },
          },
          grid: {
            xaxis: {
              lines: {
                show: false,
              },
            },
            row: {
              colors: undefined,
              opacity: 0.5,
            },
          },
          xaxis: {
            categories: labels,
            labels: {
              style: {
                fontSize: "0.50vw",
              },
            },
          },
          yaxis: {
            labels: {
              formatter: function(val) {
                return val.toNumber(true);
              },
              style: {
                colors: TailwindColors.teal["500"],
                fontSize: "12px",
              },
            },
            tickAmount: 8,
          },
        },
      };
    },
  },
};
</script>
