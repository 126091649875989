import { TotalSummaryModel } from "shared/models/TotalSummaryModel.js";

/**
 * @property {Number} revenueTotal
 * @property {Number} doorsTotal
 * @property {Number} doorsAdded
 * @property {Number} doorsRemoved
 * @property {Number} vacantListed
 * @property {Number} vacantUnlisted
 * @property {Number} occupiedListed
 * @property {Number} occupiedUnlisted
 * @property {RevenueHistoryCollection} revenueHistory
 * @property {SummaryModel} previousPeriod
 * @property {String} id
 * @property {String} name
 */
export class CompanyModel extends TotalSummaryModel {
  get fields() {
    const fields = super.fields;
    return fields.concat([{
      name: "id",
    }, {
      name: "name",
    }]);
  }
}
