<template>
  <div class="input-form-container">
    <CoreCard v-if="!showEdit">
      <template #body>
        <div class="flex flex-col justify-center items-center">
          <div>
            <img
              v-if="isLandingScreen"
              :src="LandingScreen"
              class="landing-screen"
              alt="Landing Screen"
            >
          </div>
          <CoreText
            v-if="isLandingScreen"
            value="Begin compiling your data by selecting your brand above."
            class="text-lg text-gray-800"
          />
          <img
            v-else
            src="/img/LoadingScreen.svg"
            alt="Loading Screen"
          >
        </div>
      </template>
    </CoreCard>
    <CoreCard v-else>
      <template #body>
        <div class="flex justify-center">
          <div
            v-show="isLoading"
            class="flex absolute z-10 flex-col items-center place-content-center w-full h-full"
          >
            <div class="absolute w-full h-full bg-gray-300 opacity-60" />
            <CoreLoadingBar class="w-28 h-2" />
          </div>
        </div>
        <div class="flex">
          <div class="w-1/2 right-border-one-pixel">
            <CoreText
              value="Doors"
              class="ml-6 text-lg font-medium"
            />
            <div class="m-2 bg-gray-50 door-section rounded-s">
              <div>
                <div>
                  <div class="relative-position">
                    <div
                      class="flex"
                    >
                      <CoreText
                        value="Total Doors"
                        class="text-sm font-normal text-gray-600"
                      />
                      <Tooltip>
                        Total managed doors at month end. This should NOT include HOA
                        doors if your brand manages HOA.
                      </Tooltip>
                    </div>
                    <CoreText
                      v-if="!isPreview"
                      :value="previousMonth.totalDoors + formData.doorsAdded + formData.totalAcquiredDoors + formData.doorsTransferredIn - formData.doorsRemoved - formData.doorsTransferredOut"
                      class="text-sm font-normal text-gray-600"
                    />
                    <CoreText
                      v-else
                      :value="formData.doorsTotal"
                      class="text-sm font-normal text-gray-600"
                    />
                  </div>
                </div>
              </div>
              <div class="">
                <FRPInputFieldWithTooltip
                  value="doorsAdded"
                  :locked="isPreview"
                  label="Total New Doors"
                  :type="'integer'"
                >
                  Total number of new doors at month end that were added by enrolling/signing on new clients. This should NOT include properties in total ACQUIRED doors, HOA doors NOR “RENT/LEASE Only” or dummy/test accounts.
                </FRPInputFieldWithTooltip>
                <div
                  v-if="hideAcquiredDoors"
                  class="pt-2 text-sm font-medium leading-4 text-indigo-600 cursor-pointer"
                  @click="onAcquiredDoorsClick"
                >
                  + Add Acquired Doors
                </div>
                <FRPInputFieldWithTooltip
                  v-else
                  value="totalAcquiredDoors"
                  :locked="isPreview"
                  label="Total Acquired Doors"
                  :type="'integer'"
                  class="pt-2"
                >
                  Total number of new doors at month end that were added via acquisition (m&a) of a property management portfolio/company. This should NOT include properties counted in total NEW doors, HOA doors NOR “RENT/LEASE Only” or dummy/test accounts.
                </FRPInputFieldWithTooltip>
              </div>
              <div>
                <FRPInputFieldWithTooltip
                  value="doorsRemoved"
                  :locked="isPreview"
                  label="Total Lost Doors"
                  :type="'integer'"
                >
                  Total number of deactivated properties at month end. Deactivated
                  means that you no longer manage the property and it has been
                  removed from your property count. <br>
                  <br>
                  All work has been completed and the account is closed out. This
                  should NOT include HOA doors OR “RENT/LEASE Only” or dummy/test
                  accounts.
                </FRPInputFieldWithTooltip>
                <div
                  v-if="hideTransferredDoors"
                  class="pt-2 text-sm font-medium leading-4 text-indigo-600 cursor-pointer"
                  @click="onTransferredDoorsClick"
                >
                  + Add transferred doors
                </div>
                <div
                  v-else
                  class="flex-col pt-2"
                >
                  <div class="flex relative-position">
                    <CoreText
                      value="Total Transferred Doors"
                      class="text-sm font-normal text-gray-600"
                    />
                    <Tooltip
                      class="ml-2 flex-start tooltip-icon"
                    >
                      <slot>
                        Total number of doors moved due to brand consolidation.<br>
                        This should NOT include properties counted in HOA doors.
                      </slot>
                    </Tooltip>
                  </div>
                  <div class="flex">
                    <div class="flex pt-2 pr-2">
                      <span class="inline-flex items-center px-3 text-sm font-medium leading-5 text-gray-700 bg-gray-400 rounded-l-md border border-r-0 border-gray-400">In</span>
                      <CoreFieldInteger
                        v-model="formData.doorsTransferredIn"
                        class="rounded-l-none"
                        :is-disabled="isPreview"
                        placeholder="# of Doors"
                      />
                    </div>
                    <div class="flex pt-2">
                      <span class="inline-flex items-center px-3 text-sm font-medium leading-5 text-gray-700 bg-gray-400 rounded-l-md border border-r-0 border-gray-400">Out</span>
                      <CoreFieldInteger
                        v-model="formData.doorsTransferredOut"
                        class="rounded-l-none"
                        :is-disabled="isPreview"
                        placeholder="# of Doors"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="m-2 bg-gray-50 door-section rounded-s">
              <div>
                <div class="relative-position">
                  <div
                    class="flex"
                  >
                    <CoreText
                      value="Total Doors Under HOA Management"
                      class="text-sm font-normal text-gray-600"
                    />
                    <Tooltip>
                      Total Doors Under HOA Management: HOA Management ONLY. Total number doors under HOA management at month end.
                    </Tooltip>
                  </div>
                  <CoreText
                    v-if="!isPreview"
                    :value="`${previousMonth.totalHOADoors + formData.totalNewDoorsUnderHOA + formData.totalAcquiredDoorsUnderHOA + formData.hoaDoorsTransferredIn - formData.totalLostDoorsUnderHOA - formData.hoaDoorsTransferredOut}`"
                    class="text-sm font-normal text-gray-600"
                  />
                  <CoreText
                    v-else
                    :value="formData.doorsUnderHOA"
                    class="text-sm font-normal text-gray-600"
                  />
                </div>
              </div>
              <div>
                <FRPInputFieldWithTooltip
                  value="totalNewDoorsUnderHOA"
                  :locked="isPreview"
                  label="Total New Doors Under HOA Management"
                  :type="'integer'"
                >
                  HOA Management ONLY - Total number of NEW doors under HOA management. Please include the City they are located in in COMMENT box at bottom of input form. This should NOT include properties in total ACQUIRED HOA doors.
                </FRPInputFieldWithTooltip>
                <div
                  v-if="hideAcquiredHOADoors"
                  class="pt-2 text-sm font-medium leading-4 text-indigo-600 cursor-pointer"
                  @click="onAcquiredHOADoorsClick"
                >
                  + Add acquired doors under HOA management
                </div>
                <FRPInputFieldWithTooltip
                  v-else
                  value="totalAcquiredDoorsUnderHOA"
                  :locked="isPreview"
                  label="Total Acquired Doors Under HOA Management"
                  :type="'integer'"
                  class="pt-2"
                >
                  HOA Management ONLY - Total number of newly ACQUIRED doors under HOA management at month end that were added via acquisition (m&a) of an HOA portfolio/management company. Please include the City they are located in in COMMENT box at bottom of input form. This should NOT include properties counted in total NEW HOA doors.
                </FRPInputFieldWithTooltip>
              </div>
              <div>
                <FRPInputFieldWithTooltip
                  value="totalLostDoorsUnderHOA"
                  :locked="isPreview"
                  label="Total Lost Doors Under HOA Management"
                  :type="'integer'"
                >
                  HOA Management ONLY - Total number of LOST doors under HOA
                  management.
                </FRPInputFieldWithTooltip>
                <div
                  v-if="hideTransferredHOADoors"
                  class="pt-2 text-sm font-medium leading-4 text-indigo-600 cursor-pointer"
                  @click="onTransferredHOADoorsClick"
                >
                  + Add transferred doors under HOA management
                </div>
                <div
                  v-else
                  class="flex-col pt-2"
                >
                  <div class="flex relative-position">
                    <CoreText
                      value="Total Transferred Doors Under HOA Management"
                      class="text-sm font-normal text-gray-600"
                    />
                    <Tooltip
                      class="ml-2 flex-start tooltip-icon"
                    >
                      <slot>
                        Total number of HOA managed doors moved due to brand consolidation.
                      </slot>
                    </Tooltip>
                  </div>
                  <div class="flex">
                    <div class="flex pt-2 pr-2">
                      <span class="inline-flex items-center px-3 text-sm font-medium leading-5 text-gray-700 bg-gray-400 rounded-l-md border border-r-0 border-gray-400">In</span>
                      <CoreFieldInteger
                        v-model="formData.hoaDoorsTransferredIn"
                        class="rounded-l-none"
                        :is-disabled="isPreview"
                        placeholder="# of Doors"
                      />
                    </div>
                    <div class="flex pt-2">
                      <span class="inline-flex items-center px-3 text-sm font-medium leading-5 text-gray-700 bg-gray-400 rounded-l-md border border-r-0 border-gray-400">Out</span>
                      <CoreFieldInteger
                        v-model="formData.hoaDoorsTransferredOut"
                        class="rounded-l-none"
                        :is-disabled="isPreview"
                        placeholder="# of Doors"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <CoreText
              value="Rent"
              class="ml-6 text-lg font-medium"
            />
            <div class="form-section">
              <FRPInputFieldWithTooltip
                value="rentCollected"
                :locked="isPreview"
                label="Rent Collected"
                :type="'currency'"
              >
                Total amount of rent collected at month end (for the last 30
                days). This should NOT include HOA doors if your company manages
                HOA.
              </FRPInputFieldWithTooltip>
              <FRPInputFieldWithTooltip
                value="rentUnpaidZeroToThirty"
                :locked="isPreview"
                label="Total unpaid rent (0-30)"
                :type="'currency'"
              >
                Total amount of unpaid rent over the last 30 days (Rent Only).
                Total amount of unpaid rent over the last 0-30 days.This should
                NOT include HOA income if your company manages HOA.
              </FRPInputFieldWithTooltip>
              <FRPInputFieldWithTooltip
                value="rentUnpaidTotal"
                :locked="isPreview"
                label="Total amount of unpaid rent"
                :type="'currency'"
              >
                Total amount of unpaid rent (rent only). This is the total
                amount of all unpaid rent on all active properties (includes the
                last 30 days). This should NOT include HOA income if your
                company manages HOA.
              </FRPInputFieldWithTooltip>
            </div>
            <CoreText
              value="Occupancy"
              class="ml-6 text-lg font-medium"
            />
            <div class="form-section">
              <FRPInputFieldWithTooltip
                value="occupiedListed"
                :locked="isPreview"
                label="Occupied listed doors"
                :type="'integer'"
              >
                Total occupied properties at month end that are LISTED. This
                should NOT include HOA doors OR “RENT/LEASE Only.”
              </FRPInputFieldWithTooltip>
              <FRPInputFieldWithTooltip
                value="occupiedUnlisted"
                :locked="isPreview"
                label="Occupied unlisted doors"
                :type="'integer'"
              >
                Total occupied properties at month end that are NOT listed. This
                should NOT include HOA doors OR “RENT/LEASE Only.”
              </FRPInputFieldWithTooltip>
              <FRPInputFieldWithTooltip
                value="occupiedTotal"
                :locked="isPreview"
                label="Total occupied doors"
                :type="'integer'"
              >
                Total occupied properties at month end. This should NOT include
                HOA doors OR “RENT/LEASE Only.”
              </FRPInputFieldWithTooltip>
              <FRPInputFieldWithTooltip
                value="vacantListed"
                :locked="isPreview"
                label="Vacant listed doors"
                :type="'integer'"
              >
                Total number of vacant properties at month end that are LISTED
                for rent. <br>
                **This should NOT include HOA doors OR 'RENT/LEASE Only' or
                dummy/test accounts**
              </FRPInputFieldWithTooltip>
              <FRPInputFieldWithTooltip
                value="vacantUnlisted"
                :locked="isPreview"
                label="Vacant unlisted doors"
                :type="'integer'"
              >
                Total number of vacant properties at month end that are
                UNLISTED. Vacant properties that are not listed due to reasons
                such as pending move-in, waiting to be deactivated, vacant due
                to turnover work, etc. <br>
                **This should NOT include HOA doors OR 'RENT/LEASE Only' or
                dummy/test accounts**
              </FRPInputFieldWithTooltip>
              <FRPInputFieldWithTooltip
                value="vacantTotal"
                :locked="isPreview"
                label="Total vacant doors"
                :type="'integer'"
              >
                Total number of vacant properties at month end. Vacant
                properties should be vacant properties listed for rent, pending
                move-in, waiting to be deactivated, or vacant due to turnover
                work. <br>
                **This should NOT include HOA doors OR 'RENT/LEASE Only' or
                dummy/test accounts.**
              </FRPInputFieldWithTooltip>
              <FRPInputFieldWithTooltip
                value="evictionsInProcess"
                :locked="isPreview"
                label="Evictions in process"
                :type="'integer'"
              >
                Number of evictions in progress at month end.
              </FRPInputFieldWithTooltip>
            </div>
            <FRPInputFieldWithTooltip
              value="comments"
              :locked="isPreview"
              label="Comments"
              :type="'textArea'"
              class="ml-6 w-2/3"
            >
              Tell us any reasons for large increases or decreases in door
              count. If applicable, please tell us the city any new HOA doors
              are located in.
            </FRPInputFieldWithTooltip>
          </div>
          <div class="w-1/2">
            <CoreText
              value="Revenue"
              class="ml-6 text-lg font-medium"
            />
            <div class="mt-4 ml-6 w-1/2">
              <div class="total-revenue">
                <CoreText
                  value="Total Revenue"
                  class="text-sm font-normal text-gray-600"
                />
              </div>
              <CoreFieldCurrency
                v-model="formData.revenueTotal"
                placeholder="Enter amount"
                :is-disabled="isPreview"
              />
            </div>
            <div class="my-4 ml-6">
              <CoreText
                value="Revenue By Account"
                class="text-lg font-medium"
              />
            </div>
            <div class="overflow-y-auto">
              <InputFormGLCodes :category="formData.revenue" />
            </div>
          </div>
        </div>
      </template>
    </CoreCard>
  </div>
</template>

<script>
import { CoreCard,
  CoreText,
  CoreFieldCurrency,
  CoreFieldInteger,
  CoreLoadingBar,
  EnumsButtonVersions } from "@purepm/core-ui";
import Tooltip from "ui/components/Tooltip.vue";
import { computed } from "vue";
import FormStore from "ui/store/FormStore.js";
import CompanyStore from "ui/store/CompanyStore.js";
import InputFormGLCodes from "ui/components/InputFormGLCodes.vue";
import FRPInputFieldWithTooltip from "ui/components/FRPInputFieldWithTooltip.vue";
import LandingScreen from "ui/assets/img/LandingScreen.svg";

export default {
  name: "InputForm",
  components: {
    FRPInputFieldWithTooltip,
    InputFormGLCodes,
    CoreCard,
    CoreText,
    CoreFieldCurrency,
    CoreLoadingBar,
    CoreFieldInteger,
    Tooltip,
  },
  setup() {
    const store = FormStore.inject();
    const companyStore = CompanyStore.inject();
    const hideAcquiredDoors = computed(() => store.getHideAcquiredDoors.value);
    const hideTransferredDoors = computed(() => store.getHideTransferredDoors.value);
    const hideAcquiredHOADoors = computed(() => store.getHideAcquiredHOADoors.value);
    const hideTransferredHOADoors = computed(() => store.getHideTransferredHOADoors.value);


    const onTransferredHOADoorsClick = () => {
      store.showTransferredHOADoors();
    };
    const onAcquiredHOADoorsClick = () => {
      store.showAcquiredHOADoors();
    };
    const onTransferredDoorsClick = () => {
      store.showTransferredDoors();
    };
    const onAcquiredDoorsClick = () => {
      store.showAcquiredDoors();
    };
    const isPreview = computed({
      get: () => store.getIsPreview.value,
    });
    const showEdit = computed({
      get: () => store.getShowEdit.value,
    });
    const isLoading = computed({
      get: () => store.getIsLoading.value,
    });
    const isLandingScreen = computed({
      get: () => store.getLandingScreen.value,
    });

    const cancelButtonVersion = computed(
      () => EnumsButtonVersions.TRANSPARENT_WITH_BACKGROUND,
    );

    companyStore.fetchCompanies();
    return {
      formData: store.getFormData.value,
      previousMonth: store.previousMonth.value,
      cancelButtonVersion,
      companyStore,
      isPreview,
      showEdit,
      isLoading,
      LandingScreen,
      isLandingScreen,
      hideAcquiredDoors,
      hideAcquiredHOADoors,
      hideTransferredDoors,
      hideTransferredHOADoors,
      onAcquiredDoorsClick,
      onAcquiredHOADoorsClick,
      onTransferredDoorsClick,
      onTransferredHOADoorsClick,
    };
  },
};
</script>

<style scoped>
  .form-section {
    @apply gap-6 py-4 px-6 sm:grid sm:grid-cols-1 lg:grid lg:grid-cols-2 flex flex-col;
  }
  .door-section {
    @apply gap-6 py-4 px-6 sm:grid sm:grid-cols-1 lg:grid lg:grid-cols-3 flex flex-col;
  }
  .flag-border {
    border-left-color: #2a6cf9;
    border-left-width: 10px;
  }
  .right-border-one-pixel {
    border-right-color: #dde1e7;
    border-right-width: 1px;
  }
  .landing-screen {
    height: 350px;
  }
  .input-form-container {
    height: calc(100vh - 60px);
  }
  .total-revenue {
    margin-bottom: 3px;
  }
  .relative-position {
      position: relative;
  }
</style>
