import { parseDate } from "shared/utilities.js";
import { Month } from "shared/enums/Month.js";
import { Quarter } from "shared/enums/Quarter.js";

const totalMonths = Month.count;
const quarterKeys = Quarter.keys;
const proto = Date.prototype;
/**
 * @typedef DateConfig
 * @property {Number} [year=date.getFullYear()]
 * This will specify which year you want to use.
 * @property {Number} [yearOffset=0]
 * This will add to or subtract from the year passed in, so it's another way of modifying the year value
 * @property {Number} [month=date.getMonth()]
 * This will specify which month you want to use, in the form of 0-11 (as JavaScript uses 0-based counting).  You can
 * specify negative or positive to go in the past or future.
 * @property {Number} [monthOffset=0]
 * This will add to or subtract from the month passed in, so it's another way of modifying the month value
 * @property {Number} [quarter=null]
 * This is only useful when you're wanting to use dates based on quarters, and you're using the associated methods, like
 * getStartOfQuarter.  This is in the form of 1-4.
 * @property {Number} [quarterOffset=0]
 * This will add to or subtract from the quarter passed in, so it's another way of modifying the quarter value
 * @property {Number} [date=1]
 * This is the actual date that you would like to use, in the form of 1-31... if you pass in a date that goes over the
 * number of days in that month, it'll then calculate to wherever it is in the next month.  Same if you pass in a value
 * less than 1.  0 always means that it's the first day of the previous month.
 * @property {Boolean} [isEnd=false]
 * This will give you the end of the month
 */

/**
 * @param {DateConfig} config
 */
Date.parseQuarter = function(config = {}) {
  return new Date().getStartOfQuarter(config);
};

Date.inRange = function(date, start, end) {
  return date <= end && date >= start;
};

Date.isEqual = function(first, second) {
  first = parseDate(first);
  second = parseDate(second);
  return first?.getTime() === second?.getTime();
};

proto.equals = function(value) {
  return Date.isEqual(this, value);
};

proto.getShortMonthLabel = function() {
  return this.toLocaleDateString("en-US", {
    month: "short",
  });
};

/**
 * Will output in the format of mm/dd/yyyy
 * @returns {string}
 */
proto.toMonthDayYear = function() {
  return this.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
};

/**
 * @param {DateConfig} config
 * @returns {Date}
 */
proto.getStartOfMonth = function(config = {}) {
  let {
    year = this.getFullYear(),
    yearOffset = 0,
    month = this.getMonth(),
    monthOffset = 0,
    date = 1,
    isEnd = false,
  } = config;
  if (isEnd) {
    date = 0;
    monthOffset += 1;
  }
  return new Date(year + yearOffset, month + monthOffset, date);
};

/**
 * @param {DateConfig} config
 * @returns {Date}
 */
proto.getEndOfMonth = function(config = {}) {
  config.isEnd = true;
  return this.getStartOfMonth(config);
};

/**
 * @param {DateConfig} config
 * @returns {Date}
 */
proto.getStartOfQuarter = function(config = {}) {
  let {
    month = this.getMonth(),
    isEnd,
    quarter,
    quarterOffset = 0,
    monthOffset = 0,
    yearOffset = 0,
  } = config;
  if (quarter != null) {
    // Months are 0-based, so we have to subtract 1 from the quarter, as it's not 0-based
    month = (quarter - 1) * 3;
  }
  if (quarterOffset) {
    monthOffset += quarterOffset * 3;
  }
  month += monthOffset;
  if (month < Month.January || month > Month.December) {
    const isPastDate = month < 0;
    // We have to take the absolute value when we're in the past year, as the calculations below depend on positivity
    month = Math.abs(month);
    let years = month / totalMonths;
    // We multiply by -1 because we need to subtract these years
    years = isPastDate ? Math.ceil(years) * -1 : Math.floor(years);
    // We need to make sure we get the remainder, which is the actual month we want
    month %= totalMonths;
    /* If we're in the previous year, then we actually need to re-orient to which month that would be in negative terms
     * e.g. month = -1 is actually December of the previous year, so 12 - 1 = 11 (0-based makes this December) */
    if (isPastDate) {
      month = totalMonths - month;
    }
    config.yearOffset = yearOffset + years;
  }
  if (month < Month.April) {
    // Q1 (Jan 1 - Mar 31)
    month = isEnd ? Month.March : Month.January;
  } else if (month < Month.July) {
    // Q2 (Apr 1 - Jun 30)
    month = isEnd ? Month.June : Month.April;
  } else if (month < Month.October) {
    // Q3 (Jul 1 - Sep 30)
    month = isEnd ? Month.September : Month.July;
  } else {
    // Q4 (Oct 1 - Dec 31)
    month = isEnd ? Month.December : Month.October;
  }
  config.month = month;
  config.monthOffset = 0;
  return this.getStartOfMonth(config);
};

/**
 * @param {DateConfig} config
 * @returns {Date}
 */
proto.getEndOfQuarter = function(config = {}) {
  config.isEnd = true;
  return this.getStartOfQuarter(config);
};

/**
 * @param {DateConfig} config
 * @returns {Date}
 */
proto.getStartOfYear = function(config = {}) {
  config.month = Month.January;
  return this.getStartOfMonth(config);
};

/**
 * @param {DateConfig} config
 * @returns {Date}
 */
proto.getEndOfYear = function(config = {}) {
  config.month = Month.December;
  config.isEnd = true;
  return this.getStartOfMonth(config);
};

proto.getQuarter = function() {
  const month = this.getMonth();
  let quarter = 4;
  if (month < Month.April) {
    quarter = 1;
  } else if (month < Month.July) {
    // Q2 (Apr 1 - Jun 30)
    quarter = 2;
  } else if (month < Month.October) {
    // Q3 (Jul 1 - Sep 30)
    quarter = 3;
  }
  return quarter;
};

proto.getQuarterLabel = function() {
  return quarterKeys[this.getQuarter() - 1];
};
