import { computed, inject, provide, reactive } from "vue";
import axios from "axios";
import { BASE_API } from "ui/Constants.js";

const CompanyStore = {
  init: () => {
    const state = reactive({
      companies: [],
      isLoading: false,
    });

    // Getters
    const getAllCompanies = computed(() => state.companies);
    const isLoading = computed(() => state.isLoading);

    // Mutations

    // Actions
    const fetchCompanies = () => {
      state.isLoading = true;
      return axios.get(`${BASE_API}/companies`).then((response) => {
        state.companies = response.data.map((company) => {
          return {
            value: company._id,
            label: company.name,
          };
        });
        state.isLoading = false;
      });
    };

    return Promise.all([
      provide("isLoading", isLoading),
      provide("fetchCompanies", fetchCompanies),
      provide("getAllCompanies", getAllCompanies),
    ]);
  },

  inject: () => ({
    isLoading: inject("isLoading"),
    fetchCompanies: inject("fetchCompanies"),
    getAllCompanies: inject("getAllCompanies"),
  }),
};

export default CompanyStore;
