import { AggregateType } from "shared/enums/AggregateType";

export function createChartDateLabel(labels, aggregateType, date) {
  if (aggregateType === AggregateType.Quarterly) {
    labels.push(`${new Date(date.getUTCFullYear(), date.getUTCMonth(), 1).getQuarterLabel()} ${date.getUTCFullYear()}`);
  } else if (aggregateType === AggregateType.Monthly) {
    labels.push(`${date.getUTCFullYear()} ${new Date(date.getUTCFullYear(), date.getUTCMonth(), 1).getShortMonthLabel()}`);
  } else {
    labels.push(date.getUTCFullYear());
  }
}
