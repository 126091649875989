<template>
  <div>
    <apexchart
      height="400"
      type="line"
      class="mt-4 bg-white rounded-lg"
      :options="options.chartOptions"
      :series="options.data"
    />
  </div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";
import { AggregateType } from "shared/enums/AggregateType.js";
import TailwindColors from "ui/TailwindColors.js";
import { createChartDateLabel } from "../../utilities/labelsHelper.js";


const titleKeys = AggregateType.options;
export default {
  name: "ChartTotalRevenue",
  components: {
    apexchart: VueApexCharts,
  },
  inject: ["revenueRecord", "dataFilters"],
  computed: {
    options() {
      const totalRevenueSeries = [];
      const revenuePerDoorSeries = [];
      const labels = [];
      const { aggregateType = AggregateType.Monthly } = this.dataFilters || {};
      const chartTitle = titleKeys[aggregateType].label + " trend";
      this.revenueRecord?.summary.revenueHistory.forEach((period) => {
        const {
          revenueTotal, doorRevenue, date,
        } = period;
        totalRevenueSeries.push(revenueTotal);
        revenuePerDoorSeries.push(doorRevenue);
        createChartDateLabel(labels, aggregateType, date);
      });
      const data = [
        {
          name: "Total Revenue",
          type: "bar",
          data: totalRevenueSeries,
        },
        {
          name: "Revenue Per Door",
          type: "line",
          data: revenuePerDoorSeries,
        },
      ];
      const chartOptions = {
        chart: {
          toolbar: {
            show: true,
            offsetX: -12,
            offsetY: 16,
            tools: {
              pan: false,
              selection: false,
              zoom: false,
              zoomout: false,
              reset: false,
              zoomin: false,
            },
          },
        },
        colors: [TailwindColors.teal["500"], TailwindColors.indigo["500"]],
        title: {
          text: chartTitle,
          offsetX: 10,
          offsetY: 12,
          margin: 14,
          style: {
            fontFamily: "Roboto",
          },
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          enabled: true,
          style: {
            fontFamily: "Roboto",
          },
          x: {
            show: false,
          },
        },
        legend: {
          labels: {
            useSeriesColors: true,
            fontSize: "10px",
          },
          fontFamily: "Roboto",
          position: "bottom",
          horizontalAlign: "left",
          itemMargin: {
            vertical: 20,
          },
        },
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
        labels,
        xaxis: {
          labels: {
            style: {
              fontSize: "0.50vw",
            },
            show: true,
            hideOverlappingLabels: false,
          },
        },
        yaxis: [
          {
            labels: {
              style: {
                colors: TailwindColors.teal["500"],
              },
              formatter: function(val) {
                return val.toCurrency(true);
              },
            },
            tickAmount: 8,
          },
          {
            opposite: true,
            labels: {
              style: {
                colors: TailwindColors.indigo["500"],
                fontSize: "12px",
              },
              formatter: function(val) {
                return val.toCurrency(true);
              },
            },
            tickAmount: 4,
          },
        ],
      };
      return {
        data,
        chartOptions,
      };
    },
  },
};
</script>
