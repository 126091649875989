import { Model } from "shared/Model.js";
import { isArray, isEmpty, isFunction } from "shared/utilities.js";

export class Collection extends Array {
  isCollection = true;
  filters = [];
  loadOptions = {
    url: "",
    method: "get",
  };

  constructor(...args) {
    const [data, ModelCls = Model] = args;
    super();
    /**
     * Can't think of a way to do this, other than setting a backing field... we need access to the model if we're doing
     * something like new Collection(data, MyModel), and if a collection class has get Model redefined, it will actually
     * use that, but it'll have the _Model property added to it when it's cloned... not the best solution, but it
     * doesn't hurt anything right now.
     */
    if (!this.Model) {
      this.Model = ModelCls;
    }
    this.add(data);
  }

  /**
   * @property Model
   * If you define Model in the actual class, it must be a getter!  This is due to how the constructor is called in
   * JavaScript classes... the parent constructor is always called first, and then the child, so that means whatever
   * is defined in the child is not set until after the parent's, so any child class fields would not be set when we
   * need them in the parent.
   * @returns {T}
   */

  /**
   * This will return the first record in this collection.
   * @returns {Model|undefined}
   */
  get first() {
    return this[0];
  }

  /**
   * This will return the last record in this collection.
   * @returns {Model|undefined}
   */
  get last() {
    return this[this.length - 1];
  }

  sum(field) {
    let sum = 0;
    this.forEach((record) => sum += record[field]);
    return sum;
  }

  /**
   * Removes all items from the collection.
   */
  clear() {
    this.length = 0;
  }

  add(data, removeExisting) {
    if (!data) {
      return;
    }
    data = Array.isArray(data) ? data : [data];
    if (!data.length) {
      return;
    }
    if (removeExisting) {
      this.clear();
    }
    data.map((item) => this.push(item.isModel ? item : new this.Model(item)));
  }

  getData(options) {
    const data = [];
    this.forEach((record) => {
      data.push(record.getData(options));
    });
    return data;
  }

  clone(options) {
    return new this.constructor(this.getData(options), this.Model);
  }

  findRecord(field, value) {
    let fn = field;
    if (!isFunction(fn)) {
      fn = (record) => {
        return record[field] === value;
      };
    }
    return this.find(fn);
  }

  clearFilters() {
    /* We don't use length here because we're potentially referencing the array somewhere, and once we use length of 0
     * it actually updates the pointer to an empty array... if we don't do this, it keeps the old object in memory and
     * as stored in that variable.  e.g. in removeFilters we assign filters to this.filters */
    this.filters = [];
    this.add(this._snapshot, true);
    this._snapshot = null;
  }

  removeFilters(names) {
    const filters = this.filters;
    // If the requested filters are empty or we have no filters applied, let's bail
    if (isEmpty(names) || isEmpty(filters)) {
      return;
    }
    names = isArray(names) ? names : [names];
    names.forEach((name) => {
      const foundIndex = filters.findIndex((filter) => filter.name === name);
      if (foundIndex !== -1) {
        filters.splice(foundIndex, 1);
      }
    });
    this.clearFilters();
    this.addFilters(filters);
  }

  addFilters(filters) {
    if (isEmpty(filters)) {
      return;
    }
    filters = isArray(filters) ? filters : [filters];
    let data = [];
    let dataSource = this;
    // We're adding the first filter, so let's create a shallow snapshot of our original data
    if (isEmpty(this.filters)) {
      this._snapshot = this.slice();
    }
    filters.forEach((filter, index) => {
      let { fn } = filter;
      if (index > 0) {
        dataSource = data;
        data = [];
      }
      if (!fn) {
        let {
          property, value, exact = false,
        } = filter;
        if (exact) {
          fn = (record) => record[property] === value;
        } else {
          value = new RegExp(value, "i");
          fn = (record) => value.test(record[property]);
        }
      }
      dataSource.forEach((record) => {
        if (fn(record)) {
          data.push(record);
        }
      });
    });
    this.filters = this.filters.concat(filters);
    this.add(data, true);
  }

  /**
   * We can't use native methods like filter, map, etc. in this class because our constructor takes different arguments
   * than the native methods, and there's no easy way of us supplying the arguments when those methods call the
   * constructor.  Instead, we tell these native methods to use Array as the constructor, so use those methods at your
   * own discretion... you shouldn't need to use them, but if you do, just be cautious.
   * Reference: https://javascript.info/extend-natives
   */
  static get [Symbol.species]() {
    return Array;
  }
}
