import { isEmpty } from "shared/utilities.js";

export const DEFAULT_DISPLAY_VALUE = "-";
/**
 * Convert integer dollar value to formatted
 * @param {Number} value
 * @param {Boolean} [wholeValue=false]
 * This will return whole dollars instead of including cents
 */
export function currency(value, wholeValue) {
  if (isEmpty(value)) {
    return DEFAULT_DISPLAY_VALUE;
  }
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: wholeValue ? 0 : 2,
  }).format(value);
}

export function number(value, wholeValue) {
  if (isEmpty(value)) {
    return DEFAULT_DISPLAY_VALUE;
  }
  return new Intl.NumberFormat("en-US", {
    maximumFractionDigits: wholeValue ? 0 : 2,
  }).format(value);
}
