import { FIELD_TYPES, Model } from "shared/Model.js";

/**
 * @property {Number} revenueTotal
 * @property {Number} doorsTotal
 * @property {Number} doorsAdded
 * @property {Number} doorsRemoved
 * @property {Number} vacantListed
 * @property {Number} vacantUnlisted
 * @property {Number} occupiedListed
 * @property {Number} occupiedUnlisted
 */
export class SummaryModel extends Model {
  get fields() {
    return [{
      name: "revenueTotal",
      type: FIELD_TYPES.DECIMAL,
    }, {
      name: "doorsTotal",
      type: FIELD_TYPES.INTEGER,
    }, {
      name: "doorsAdded",
      type: FIELD_TYPES.INTEGER,
    }, {
      name: "doorsRemoved",
      type: FIELD_TYPES.INTEGER,
    }, {
      name: "vacantListed",
      type: FIELD_TYPES.INTEGER,
    }, {
      name: "vacantUnlisted",
      type: FIELD_TYPES.INTEGER,
    }, {
      name: "occupiedListed",
      type: FIELD_TYPES.INTEGER,
    }, {
      name: "occupiedUnlisted",
      type: FIELD_TYPES.INTEGER,
    }];
  }

  get doorRevenue() {
    const { revenueTotal, doorsTotal } = this;
    return doorsTotal ? revenueTotal / doorsTotal : 0;
  }

  updateSummary({
    revenueTotal = 0, doorsTotal = 0, doorsAdded = 0, doorsRemoved = 0, vacantListed = 0, vacantUnlisted = 0, occupiedListed = 0, occupiedUnlisted = 0,
  }) {
    this.revenueTotal += revenueTotal;
    this.doorsTotal += doorsTotal;
    this.doorsAdded += doorsAdded;
    this.doorsRemoved += doorsRemoved;
    this.vacantListed += vacantListed;
    this.vacantUnlisted += vacantUnlisted;
    this.occupiedListed += occupiedListed;
    this.occupiedUnlisted += occupiedUnlisted;
  }
}
