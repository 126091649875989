import { EnumHelper } from "../EnumHelper.js";

const options = ["Monthly", "Quarterly", "Yearly"];
class AggregateTypeEnum extends EnumHelper {
  constructor() {
    super(options.map((item, index) => {
      return {
        [item]: index,
      };
    }));
  }
}

/**
 * @property {number} Monthly
 * @property {number} Quarterly
 * @property {number} Yearly
 */
export const AggregateType = new AggregateTypeEnum();
