<template>
  <div class="flex relative flex-col">
    <div class="overflow-visible -my-2 sm:-mx-6 lg:-mx-8">
      <div class="inline-block py-2 min-w-full align-middle sm:px-6 lg:px-8">
        <div class="border-b border-gray-200 shadow sm:rounded-b-lg">
          <table
            :class="tableClass"
            class="w-full min-w-full divide-y divide-gray-200"
            aria-label="Data Table"
          >
            <thead class="w-full bg-gray-50">
              <tr class="w-full">
                <th
                  v-for="(header, headerIndex) in headers"
                  :key="header.key"
                  :class="
                    headerClass(header.classes, headers.length, headerIndex)
                  "
                  scope="col"
                  data-test="core-data-table-header"
                  v-bind="{ ...header.props }"
                >
                  <div
                    class="flex items-center"
                    :class="{'cursor-pointer': header.sortable}"
                    data-test="core-data-table-header-title"
                    @mouseover="mouseOver(header)"
                    @mouseleave="mouseExit(header)"
                    @click="applySort(header)"
                  >
                    {{ header.value || header.key }}
                    <span
                      v-if="header.sortable"
                      class="ml-2"
                    >
                      <CoreIcon
                        v-if="arrowUp(header)"
                        :icon="Icons.CHEVRON_UP_SOLID"
                      />
                      <CoreIcon
                        v-else
                        :icon="Icons.CHEVRON_DOWN_SOLID"
                      />
                    </span>
                    <ComingSoon
                      v-if="showComingSoon(header, hoveredColumn)"
                      :version="EnumsComingSoonPosition.ABOVE"
                    />
                  </div>
                </th>
                <th
                  v-if="contextMenu"
                  key="contextMenuHeader"
                  data-test="core-data-table-header-context-menu"
                  scope="col"
                />
              </tr>
            </thead>
            <tbody class="w-full bg-white divide-gray-200">
              <CoreAnimationFade
                :is-active="animateRows"
                is-list
              >
                <tr
                  v-for="(line, idx) in filteredRows"
                  :key="idx"
                  class="hover:bg-light-blue-50"
                  :class="[rowClasses, rowClass(idx)]"
                  data-test="core-data-table-row"
                >
                  <template
                    v-for="(cell, cellIndex) in line"
                    :key="cell.key"
                  >
                    <td
                      :class="cellClass(cell.classes, headers.length, cellIndex)"
                      data-test="core-data-table-row-content"
                    >
                      <component
                        :is="cell.component"
                        v-if="cell.component"
                        :class="cell.componentClasses"
                        v-bind="{ ...cell.props }"
                      >
                        {{ cell.content }}
                      </component>
                      <template v-else>
                        {{ cell.content }}
                      </template>
                    </td>
                  </template>
                  <td
                    v-if="contextMenu"
                    v-click-away="() => onClickAway(idx)"
                    data-test="core-data-table-row-menu"
                    class="cursor-pointer"
                    @click="contextMenu ? handleClick($event, line, idx) : null"
                  >
                    <CoreIcon
                      :icon="Icons.DOTS_VERTICAL"
                      height="h-6"
                      width="w-6"
                    />
                    <CoreContextMenu
                      v-if="contextMenu"
                      :ref="`vueContextMenu${idx}`"
                      :context-menu-id="String(idx)"
                      :options="contextMenuOptions"
                      @option-clicked="option"
                    />
                  </td>
                </tr>
              </CoreAnimationFade>
            </tbody>
            <tfoot class="w-full bg-gray-50">
              <CoreAnimationFade
                :is-active="animateRows"
                is-list
              >
                <tr
                  class="w-full hover:bg-light-blue-50"
                  :class="rowClasses"
                  data-test="core-data-table-row"
                >
                  <template
                    v-for="(cell, cellIndex) in footerRows"
                    :key="cell.key"
                  >
                    <td
                      :class="cellClass(cell.classes, headers.length, cellIndex)"
                      data-test="core-data-table-row-content"
                    >
                      <component
                        :is="cell.component"
                        v-if="cell.component"
                        :class="cell.componentClasses"
                        v-bind="{ ...cell.props }"
                      >
                        {{ cell.content }}
                      </component>
                      <template v-else>
                        {{ cell.value }}
                      </template>
                    </td>
                  </template>
                  <td
                    v-if="contextMenu"
                    data-test="core-data-table-row-menu"
                    class="cursor-pointer"
                  >
                    <CoreIcon
                      :icon="Icons.DOTS_VERTICAL"
                      height="h-6"
                      width="w-6"
                    />
                    <CoreContextMenu
                      v-if="contextMenu"
                      :options="contextMenuOptions"
                      @option-clicked="option"
                    />
                  </td>
                </tr>
              </CoreAnimationFade>
            </tfoot>
          </table>
          <footer
            v-if="!hidePagination"
            class="flex justify-between items-end pb-3 bg-white"
          >
            <div class="flex justify-between items-end">
              <CoreDataPagination
                v-model="pageOffset"
                :length="totalPages"
              />
            </div>
            <div class="flex items-center mr-4 ml-6 text-sm">
              <div class="text-gray-700">
                <span>Showing&nbsp;</span>
                <span v-if="itemCount > 0">
                  <span class="font-medium">{{ startResultNumber }}</span>
                  to
                  <span class="font-medium">{{ endResultNumber }}</span>
                  of
                </span>
                <span>
                  <span class="font-medium">{{ itemCount }}</span>
                  results
                </span>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CoreAnimationFade,
  CoreContextMenu,
  CoreDataPagination,
  CoreIcon,
  CoreBadge,
  ComingSoon,
  EnumsComingSoonPosition } from "@purepm/core-ui";
import { isString } from "../../utilities/index.js";
import { directive } from "vue3-click-away";

export default {
  name: "FRPDataTable",
  components: {
    CoreContextMenu,
    CoreAnimationFade,
    CoreDataPagination,
    CoreIcon,
    CoreBadge,
    ComingSoon,
  },
  emits: ["sort", "page"],
  directives: {
    ClickAway: directive,
  },
  props: {
    pageSize: {
      type: Number,
      default: 20,
    },
    backendPagination: {
      type: Boolean,
      default: false,
    },
    headers: {
      type: Array,
      default: () => [],
    },
    rows: {
      type: Array,
      default: () => [],
    },
    footerRows: {
      type: Array,
      default: () => [],
    },
    hidePagination: {
      type: Boolean,
      default: false,
    },
    rowClasses: {
      type: [Object, Array, String],
      default: "",
    },
    contextMenu: {
      type: Boolean,
      default: false,
    },
    contextMenuOptions: {
      type: Array,
      default: null,
    },
    contextMenuOptionClickHandler: {
      type: Function,
      default: null,
    },
    contextMenuId: {
      type: String,
      default: null,
    },
    animateRows: {
      type: Boolean,
      default: false,
    },
    totalDocs: {
      type: Number,
      default: 0,
    },
    /**
     * This can either be a string or have the structure listed below.  If it's a string, it's treated as the field,
     * and the default asc sort will be applied
     * @typedef CoreDataTableSorter
     * @property {String} field
     * @property {String} direction
     * This will be either asc or desc
     */
    initialSort: {
      type: [String, Object],
      default: "",
    },
  },
  data() {
    let field;
    let direction;
    const initialSort = this.initialSort;
    if (initialSort) {
      if (isString(initialSort)) {
        field = initialSort;
      } else {
        ({
          field,
          direction,
        } = initialSort);
      }
    }
    return {
      EnumsComingSoonPosition,
      sortField: field,
      sortDir: direction || "asc",
      pageStart: 0,
      pageStop: 0,
      pageOffset: 1,
      hover: false,
      hoveredColumn: null,
    };
  },
  computed: {
    itemCount() {
      return this.totalDocs || this.rows.length;
    },
    pageLimit() {
      return this.pageSize;
    },
    totalPages() {
      return Math.ceil((this.itemCount) / (this.pageLimit));
    },
    startResultNumber() {
      const min = (this.pageOffset - 1) * this.pageLimit + 1;
      return this.itemCount > min ? min : this.itemCount;
    },
    endResultNumber() {
      const max = this.pageOffset * this.pageLimit;
      return this.itemCount > max ? max : this.itemCount;
    },
    filteredRows() {
      if (this.backendPagination) {
        return this.rows;
      }
      return this.rows.slice(this.pageStart, this.pageStop);
    },
    tableClass() {
      return {
        "border-b-2": !this.hidePagination,
      };
    },
  },
  methods: {
    /**
     * @returns {CoreDataTableSorter}
     */
    getLastSort() {
      return {
        field: this.sortField,
        direction: this.sortDir,
      };
    },
    arrowUp(header) {
      return (this.sortField === header.sortKey || this.sortField === header.key) && this.sortDir === "desc";
    },
    applySort(header) {
      if (!header.sortable) return;
      const field = header.sortKey || header.key;
      if (this.sortField === field) {
        this.sortDir = this.sortDir === "asc" ? "desc" : "asc";
      } else {
        this.sortField = field;
        this.sortDir = "asc";
      }
      /* Whenever the sorting changes, we need to make sure we reset the page we're on because it's no longer a valid
       * value that was there */
      this.pageOffset = 1;
      this.$emit("sort", this.getLastSort());
    },
    cellClass(classes, numberOfHeaders, cellIndex) {
      if (typeof classes !== "undefined") {
        return cellIndex !== 0 ? `whitespace-nowrap ${classes}` : classes;
      }
      return {
        "whitespace-nowrap": cellIndex !== 0,
        ["px-6 py-4"]: typeof classes === "undefined",
      };
    },
    headerClass(classes) {
      if (typeof classes === "undefined") {
        return "px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider overflow-hidden";
      }
      return classes;
    },
    mouseOver(header) {
      this.hoveredColumn = header;
    },
    mouseExit(header) {
      this.hoveredColumn = null;
    },
    showComingSoon(header, hoveredColumn) {
      return header.comingSoon && header.key === hoveredColumn?.key;
    },
    rowClass(idx) {
      return {
        "border-b-2 hover:bg-light-blue-50":
            !this.hidePagination || this.filteredRows.length - 1 !== idx,
      };
    },
    handleClick(event, item, idx) {
      if (this.contextMenu) {
        event.preventDefault();
        if (this.$refs["vueContextMenu" + idx]) {
          this.$refs["vueContextMenu" + idx].showMenu(item);
        }
      }
    },
    onClickAway(idx) {
      if (
        this.contextMenu && this.$refs["vueContextMenu" + idx] && typeof this.$refs["vueContextMenu" + idx].onClickAway === "function"
      ) {
        this.$refs["vueContextMenu" + idx].onClickAway();
      }
    },
    option(ev) {
      const { contextMenuOptionClickHandler } = this;
      if (contextMenuOptionClickHandler) {
        contextMenuOptionClickHandler(ev);
      }
    },
    resetPageOffset() {
      this.pageOffset = 1;
    },
  },
  watch: {
    pageOffset(pageOffset, oldValue) {
      const start = (pageOffset - 1) * this.pageLimit;
      const stop = pageOffset * this.pageLimit;
      this.pageStart = start;
      this.pageStop = stop;
      this.$emit("page", {
        page: pageOffset,
        start,
        stop,
      });
    },
  },
  mounted() {
    this.pageStop = this.pageLimit;
  },
};
</script>
