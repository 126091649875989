<template>
  <div
    class="ml-2 h-full text-sm rounded-md tooltip"
  >
    <CoreIcon
      id="icon"
      aria-describedby="tooltip"
      :icon="iconForCore"
      :color="`text-indigo-300`"
      :height="`h-5`"
      :width="`w-5`"
      @mouseenter="handleShow($event)"
      @mouseleave="handleHide($event)"
      @focus="handleShow($event)"
      @blur="handleHide($event)"
    />
    <div
      id="tooltip"
      role="tooltip"
      class="text-sm tooltipText"
    >
      <slot />
      <div
        id="arrow"
        class="tooltipArrow"
        data-popper-arrow
      />
    </div>
  </div>
</template>

<script>
import { InformationCircleIcon } from "@heroicons/vue/outline";
import { ref, computed } from "vue";
import { createPopper } from "@popperjs/core";
import { CoreIcon } from "@purepm/core-ui";

export default {
  name: "Tooltip",
  components: {
    CoreIcon,
  },
  setup(props) {
    const icon = ref(null);
    const tooltip = ref(null);
    const insertElement = (i, t) => {
      icon.value = i;
      tooltip.value = t;
    };
    const iconForCore = InformationCircleIcon;
    const popper = computed(() => {
      return createPopper(icon.value, tooltip.value, {
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 8],
            },
          },
          {
            name: "flip",
            enabled: true,
            options: {
              fallbackPlacements: ["right", "bottom"],
            },
          },
        ],
        strategy: "absolute",
        placement: "top",
      } );
    });

    const show = () => {
      tooltip.value.setAttribute("data-show", "");
      popper.value.setOptions((options) => ({
        ...options,
        modifiers: [
          ...options.modifiers,
          {
            name: "eventListeners",
            enabled: true,
          },
        ],
      }));
      popper.value.update();
    };

    function hide() {
      tooltip.value.removeAttribute("data-show");
      popper.value.setOptions((options) => ({
        ...options,
        modifiers: [
          ...options.modifiers,
          {
            name: "eventListeners",
            enabled: false,
          },
        ],
      }));
    }

    const handleShow = (event) => {
      if (icon.value === null || tooltip.value === null) {
        insertElement(event.target, event.target.parentElement.querySelector("#tooltip"));
      }
      show();
    };
    const handleHide = (event) => {
      if (icon.value === null || tooltip.value === null) {
        insertElement(event.target, event.target.parentElement.querySelector("#tooltip"));
      }
      hide();
    };

    return {
      iconForCore,
      handleShow,
      handleHide,
      props,
    };
  },
};
</script>

<style scoped>
#tooltip {
  background: #292E3F;
  color: white;
  padding: 15px;
  border-radius: 4px;
  display: none;
  z-index: 99;
  min-width: 250px;
  text-align: center;
}

#tooltip[data-show] {
  display: block;
}

#arrow,
#arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
}

#arrow {
  visibility: hidden;
}

#arrow::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
}

#tooltip[data-popper-placement^='top'] > #arrow {
  bottom: -4px;
}

#tooltip[data-popper-placement^='bottom'] > #arrow {
  top: -4px;
}

#tooltip[data-popper-placement^='left'] > #arrow {
  right: -4px;
}

#tooltip[data-popper-placement^='right'] > #arrow {
  left: -4px;
}
</style>
