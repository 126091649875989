<template>
  <div class="flex flex-col border-x">
    <FRPDataTable
      :headers="TableCompaniesHeaders"
      :rows="data"
      class="flex-1 data-table-frp"
      hide-pagination
      :page-size="1000"
      :initial-sort="{field: 'name', direction: 'asc'}"
      :footer-rows="summaryHeaders"
      @sort="onSortChange"
    />
  </div>
</template>

<script>
import FRPDataTable from "ui/components/FRPDataTable.vue";
import BadgePercentChange from "ui/components/BadgePercentChange.vue";
import { inject } from "vue";
import { commonSort } from "shared/utilities.js";

export const TableCompaniesHeaders = [{
  key: "name",
  value: "Brand",
  sortable: true,
}, {
  key: "revenueTotal",
  value: "Total Revenue",
  sortable: true,
  formatter(value) {
    return value.toCurrency();
  },
}, {
  key: "revenueTotalChange",
  value: "Revenue Change",
  sortable: true,
  component: BadgePercentChange,
}, {
  key: "doorsTotal",
  value: "Total Doors",
  sortable: true,
  formatter(value) {
    return value.toNumber();
  },
}, {
  key: "doorsAdded",
  value: "New Doors",
  sortable: true,
  formatter(value) {
    return value.toNumber();
  },
}, {
  key: "doorsRemoved",
  value: "Lost Doors",
  sortable: true,
  formatter(value) {
    return value.toNumber();
  },
}];

export default {
  name: "TableCompanies",
  components: {
    FRPDataTable,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    summaryHeaders: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const revenueRecord = inject("revenueRecord");
    return {
      TableCompaniesHeaders,
      onSortChange({ field, direction }) {
        const { companies } = revenueRecord.value || {};
        companies?.sort((lhs, rhs) => commonSort(lhs[field], rhs[field], direction === "asc" ? 1 : -1));
      },
    };
  },
};
</script>
