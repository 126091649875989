import { SummaryModel } from "shared/models/SummaryModel.js";
import { FIELD_TYPES } from "shared/Model.js";
import { RevenueHistoryCollection } from "shared/models/RevenueHistoryCollection.js";
import { percentChange } from "shared/utilities.js";

/**
 * @property {Number} revenueTotal
 * @property {Number} doorsTotal
 * @property {Number} doorsAdded
 * @property {Number} doorsRemoved
 * @property {Number} vacantListed
 * @property {Number} vacantUnlisted
 * @property {Number} occupiedListed
 * @property {Number} occupiedUnlisted
 * @property {RevenueHistoryCollection} revenueHistory
 * @property {SummaryModel} previousPeriod
 */
export class TotalSummaryModel extends SummaryModel {
  get fields() {
    const fields = super.fields;
    return fields.concat([{
      name: "revenueHistory",
      type: FIELD_TYPES.COLLECTION,
      collection: RevenueHistoryCollection,
    }, {
      name: "previousPeriod",
      type: FIELD_TYPES.MODEL,
      model: SummaryModel,
    }]);
  }

  get revenueTotalChange() {
    return percentChange(this.revenueTotal, this.previousPeriod.revenueTotal);
  }

  get doorsTotalChange() {
    return percentChange(this.doorsTotal, this.previousPeriod.doorsTotal);
  }

  get doorsAddedChange() {
    return percentChange(this.doorsAdded, this.previousPeriod.doorsAdded);
  }

  get doorsRemovedChange() {
    return percentChange(this.doorsRemoved, this.previousPeriod.doorsRemoved);
  }
}
