const proto = String.prototype;

proto.capitalize = function() {
  return `${this[0].toUpperCase()}${this.substring(1)}`;
};

proto.equals = function(value) {
  return this.toLowerCase() === value?.toLowerCase();
};

proto.toCamelCase = function() {
  const value = this.replace(/\s/g, "");
  return `${value[0].toLowerCase()}${value.substring(1)}`;
};
