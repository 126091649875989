import { EnumHelper } from "shared/EnumHelper.js";

const options = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
class MonthEnum extends EnumHelper {
  constructor() {
    super(options.map((item, index) => {
      return {
        [item]: index,
      };
    }));
  }
}

/**
 * @property {number} January
 * @property {number} February
 * @property {number} March
 * @property {number} April
 * @property {number} May
 * @property {number} June
 * @property {number} July
 * @property {number} August
 * @property {number} September
 * @property {number} October
 * @property {number} November
 * @property {number} December
 */
export const Month = new MonthEnum();
