import { computed } from "vue";

export function useMultiSelectOptions(options, item) {
  return computed({
    get() {
      return options.find((option) => option.value === item.value);
    },
    set(selection) {
      item.value = selection?.value;
    },
  });
}
