<template>
  <div class="grid grid-cols-12">
    <SummaryTile
      :summary="summaryTileRecords.totalRevenue"
      class="col-span-3 mr-2 rounded-lg"
    >
      <ChartTotalRevenue />
    </SummaryTile>

    <div class="flex flex-col col-span-3 rounded-l-lg">
      <div class="flex h-28 border-b">
        <SummaryTile
          :summary="summaryTileRecords.totalDoors"
          class="flex-1 rounded-tl-lg"
        />
      </div>
      <ChartTotalDoors
        class="flex-1"
      />
    </div>
    <div class="flex flex-col col-span-6">
      <div class="flex h-28 border-b">
        <SummaryTile
          class="flex-1"
          :summary="summaryTileRecords.newDoors"
        />
        <SummaryTile
          :summary="summaryTileRecords.lostDoors"
          class="flex-1 rounded-tr-lg"
        />
      </div>
      <ChartNewAndLostDoors class="border-l" />
    </div>
  </div>
</template>

<script>
import { SummaryTileModel } from "ui/models/SummaryTileModel.js";
import SummaryTile from "ui/components/SummaryTile.vue";
import ChartTotalRevenue from "ui/components/ChartTotalRevenue.vue";
import ChartTotalDoors from "ui/components/ChartTotalDoors.vue";
import ChartNewAndLostDoors from "ui/components/ChartNewAndLostDoors.vue";

export default {
  name: "ContainerSummaries",
  components: {
    SummaryTile,
    ChartTotalRevenue,
    ChartTotalDoors,
    ChartNewAndLostDoors,
  },
  inject: ["revenueRecord"],
  computed: {
    summaryTileRecords() {
      const summary = this.revenueRecord?.summary;
      const totalRevenue = new SummaryTileModel({
        title: "Total Revenue",
        total: summary?.revenueTotal,
        totalChange: summary?.revenueTotalChange,
        doorRevenue: summary?.doorRevenue,
        bgClass: "bg-blue-gradient",
        titleTextClass: "text-white",
        statColorClass: "text-blue-300",
        previousPeriodTextColor: "text-gray-400",
        isCurrency: true,
      });
      const totalDoors = new SummaryTileModel({
        title: "Total Doors",
        total: summary?.doorsTotal,
        totalChange: summary?.doorsTotalChange,
        statColorClass: "text-blue-500",
      });
      const newDoors = new SummaryTileModel({
        title: "New Doors",
        total: summary?.doorsAdded,
        totalChange: summary?.doorsAddedChange,
        statColorClass: "text-teal-600",
      });
      const lostDoors = new SummaryTileModel({
        title: "Lost Doors",
        total: summary?.doorsRemoved,
        totalChange: summary?.doorsRemovedChange,
        statColorClass: "text-red-600",
      });

      return {
        totalRevenue,
        totalDoors,
        newDoors,
        lostDoors,
      };
    },
  },
};
</script>
<style>
.bg-blue-gradient {
  @apply bg-gradient-to-br from-indigo-800 to-indigo-750;
}
</style>
