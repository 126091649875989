import { EnumHelper } from "shared/EnumHelper.js";
import { parseBoolean, parseDate, parseDecimal, parseInteger, parseString } from "shared/utilities.js";
import { Collection } from "shared/Collection.js";

/**
 * @property {String} STRING
 * @property {String} INTEGER
 * @property {String} DECIMAL
 * @property {String} BOOLEAN
 * @property {String} DATE
 * @property {String} COLLECTION
 * @property {String} MODEL
 */
export const FIELD_TYPES = new EnumHelper(["string", "integer", "decimal", "boolean", "date", "collection", "model"]);

/**
 * @param {*} value
 * @param {Field} field
 * @returns {*}
 */
function parseValue(value, field) {
  let defaultValue;
  switch (field.type) {
    case FIELD_TYPES.INTEGER:
      value = parseInteger(value);
      defaultValue = 0;
      break;
    case FIELD_TYPES.DECIMAL:
      value = parseDecimal(value, field.precision);
      defaultValue = 0;
      break;
    case FIELD_TYPES.BOOLEAN:
      value = parseBoolean(value);
      defaultValue = false;
      break;
    case FIELD_TYPES.DATE:
      value = parseDate(value);
      break;
    case FIELD_TYPES.COLLECTION:
      if (field.collection) {
        // eslint-disable-next-line new-cap
        value = new field.collection(value);
      } else {
        value = new Collection(value, field.model);
      }
      break;
    case FIELD_TYPES.MODEL:
      // eslint-disable-next-line new-cap
      value = new field.model(value);
      break;
    case FIELD_TYPES.STRING:
    default:
      value = parseString(value);
      defaultValue = "";
      break;
  }
  if (value === undefined) {
    value = field.hasOwnProperty("default") ? field.default : defaultValue;
  }
  return value;
}

export class Model {
  isModel = true;

  constructor(data = {}) {
    this.loadData(data);
  }

  /**
   * @typedef Field
   * @property {String} name
   * @property {FIELD_TYPES} type
   * Default value is string
   * @property {Model} model
   * If this is specified, then that means we're using the collection type, and we have a model class that has
   * been defined somewhere.
   * @property {Collection} collection
   * If this is specified, then that means we're using the collection type, and we have a collection class that has
   * been defined somewhere.  This will be used before model, if that property is defined as well... they should never
   * be defined together.
   * @property {Number} precision
   * This value is used when using the decimal type... it'll round up to the precision decimal value.
   * Default value is 2
   * @property {*} default
   * This value is used as the default value when initializing the data
   * @property {String} format
   * TODO: Implement... this is for things like dates, if they come in as a certain format "mm/dd/yyyy" vs "dd-mm-yyyy"
   * or if it's epoch time
   * @type {Field[]}
   *
   * The reason this has to be a getter is due to inheritance in ES6 classes.  Class fields are called after a super/
   * constructor is called, so in order to override this, we can use a getter, which can be defined in the subclass and
   * called when we need to access the data.
   * See also: https://stackoverflow.com/q/61991775/1253609
   */
  get fields() {
    return [];
  }

  getFieldNames() {
    return this.fields.map((field) => field.name);
  }

  get keys() {
    return Object.keys(this);
  }

  loadData(data = {}) {
    this.clear();
    Object.assign(this, this.init(data));
  }

  clear() {
    const fields = this.fields;
    for (const field of fields) {
      const item = this[field.name];
      if (item == null) {
        continue;
      }
      if (item.isCollection) {
        item.clear();
      } else if (item.isModel) {
        item.clear();
      } else {
        this[item] = null;
      }
    }
  }

  init(data) {
    const result = {};
    const fields = this.fields;
    fields.forEach((field) => {
      const { name } = field;
      result[name] = parseValue(data[name], field);
    });
    return result;
  }

  /**
   * This method will return the data as if it's a plain old JavaScript object... it will no longer be this class, and
   * it'll deep clone the data.
   * @param {Object} options
   * This will eventually have other things in here, like an exclude to ignore specific fields from being in the result
   * @param {String[]} options.include
   * This is an array of optional fields that you'd like to include, like calculated getters
   * @returns {Object} result
   */
  getData(options = {}) {
    const { include, exclude } = options;
    const result = {};
    let fields = this.getFieldNames();
    if (include) {
      fields = fields.concat(include);
    }
    if (exclude) {
      exclude.forEach((item) => {
        const index = fields.indexOf(item);
        if (index !== -1) {
          fields.splice(index, 1);
        }
      });
    }
    fields.forEach((name) => {
      const item = this[name];
      if (item?.isCollection || item?.isModel) {
        result[name] = item.getData(options);
      } else {
        // Otherwise, we're dealing with a primitive, so it's okay to add
        result[name] = item;
      }
    });
    return result;
  }

  toClassDescription() {
    return "/**\n" + this.fields.map((field) => {
      let type = field.type || FIELD_TYPES.STRING;
      if ([FIELD_TYPES.INTEGER, FIELD_TYPES.DECIMAL].indexOf(type) !== -1) {
        type = "number";
      } else if (type === FIELD_TYPES.MODEL) {
        type = field.model.name;
      } else if (type === FIELD_TYPES.COLLECTION) {
        type = field.model?.name;
        if (type) {
          type = `${type}[]`;
        } else {
          type = field.collection?.name || FIELD_TYPES.COLLECTION;
        }
      }
      return `* @property {${type.capitalize()}} ${field.name}`;
    }).join("\n") + "\n*/";
  }
}
