<template>
  <div :class="cls">
    <CoreIcon
      :icon="isIncrease ? Icons.ARROW_UP : Icons.ARROW_DOWN"
      class="self-center mr-0.5 -ml-1 shrink-0"
      :color="isIncrease ? 'text-green-500' : 'text-red-500'"
      aria-hidden="true"
      height="h-4"
      width="w-4"
    />
    <span>
      <span class="sr-only"> {{ isIncrease ? 'Increased' : 'Decreased' }} by </span>
      {{ value.toPercent() }}
    </span>
  </div>
</template>

<script>
import { computed } from "vue";
import { CoreIcon } from "@purepm/core-ui";

export default {
  name: "BadgePercentChange",
  components: {
    CoreIcon,
  },
  props: {
    value: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const isIncrease = computed(() => props.value >= 0);
    const cls = computed(() => {
      return {
        "inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0": true,
        "bg-green-100 text-green-800": isIncrease.value,
        "bg-red-100 text-red-800": !isIncrease.value,
      };
    });
    return {
      cls,
      isIncrease,
    };
  },
};
</script>
