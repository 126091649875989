<template>
  <CoreButtonIcon
    class="ml-auto"
    :version="EnumsButtonVersions.TRANSPARENT"
    :icon="Icons.ARROWS_EXPAND"
    @click="onClickExpandChart"
  />
  <Teleport to="#floatingItems">
    <CoreDialog
      :open="maximized"
      class="p-8"
      dialog-cls="min-w-full h-full max-h-full"
      @close="onCloseMaxmizedDialog"
    >
      <template #body>
        <slot />
      </template>
    </CoreDialog>
  </Teleport>
</template>

<script>
import { EnumsButtonVersions, CoreButtonIcon, CoreDialog } from "@purepm/core-ui";
import { ref } from "vue";

export default {
  name: "ChartMaximizer",
  components: {
    CoreButtonIcon,
    CoreDialog,
  },
  setup() {
    const maximized = ref(false);
    function onClickExpandChart() {
      maximized.value = true;
    }
    function onCloseMaxmizedDialog() {
      maximized.value = false;
    }
    return {
      maximized,
      EnumsButtonVersions,
      onClickExpandChart,
      onCloseMaxmizedDialog,
    };
  },
};
</script>
