<template>
  <CoreCard
    title="Total Revenue"
    body-cls="grid grid-cols-4 flex-1"
    :has-bottom-pad="false"
  >
    <template #body>
      <ChartCompanyRevenue
        class="col-span-1"
        :data="options.companyRevenueData"
        :chart-options="options.companyRevenueChartOptions"
      />
      <TableCompanies
        class="col-span-2"
        :data="options.companyTableData"
        :summary-headers="options.companyTableSummaryHeaders"
      />
      <ChartRentalListings
        class="col-span-1"
        :data="options.rentalListingsData"
        :chart-options="options.rentalListingsChartOptions"
      />
    </template>
  </CoreCard>
</template>

<script>
import ChartRentalListings from "ui/components/ChartRentalListings.vue";
import { CoreCard } from "@purepm/core-ui";
import ChartCompanyRevenue from "ui/components/ChartCompanyRevenue.vue";
import TableCompanies, { TableCompaniesHeaders } from "ui/components/TableCompanies.vue";
import { useDefaultPieChart } from "ui/composables/chart.js";
import { inject, computed } from "vue";
import TailwindColors from "ui/TailwindColors.js";

const rentalListingsLabels = ["Vacant Listed", "Occupied Listed", "Vacant Unlisted", "Occupied Unlisted"];
export default {
  name: "ContainerCompanies",
  components: {
    TableCompanies,
    ChartCompanyRevenue,
    ChartRentalListings,
    CoreCard,
  },
  setup() {
    const revenueRecord = inject("revenueRecord");
    const options = computed(() => {
      const companyRevenueData = [];
      const companyRevenueLabels = [];
      const companyTableData = [];
      let vacantListed = 0;
      let vacantUnlisted = 0;
      let occupiedListed = 0;
      let occupiedUnlisted = 0;
      const { summary = {}, companies = [] } = revenueRecord.value || {};
      const totalCompanies = companies.length;
      const companyTableSummaryHeaders = TableCompaniesHeaders.map(({
        formatter, key, component,
      }, index) => {
        if (index === 0) {
          return {
            value: `Total: ${totalCompanies}`,
          };
        }
        const value = summary[key] || 0;
        if (component) {
          return {
            component,
            value,
            props: {
              value,
            },
          };
        }
        return {
          value: formatter ? formatter(value) : value,
        };
      });
      companies.forEach((company) => {
        const { revenueTotal } = company;
        companyRevenueData.push(revenueTotal);
        companyRevenueLabels.push(`${company.name}: ${revenueTotal.toCurrency()}`);
        vacantListed += company.vacantListed;
        vacantUnlisted += company.vacantUnlisted;
        occupiedListed += company.occupiedListed;
        occupiedUnlisted += company.occupiedUnlisted;
        companyTableData.push(TableCompaniesHeaders.map(({
          key, formatter, component,
        }) => {
          const value = company[key] || 0;
          if (component) {
            return {
              key,
              component,
              content: value,
              props: {
                value,
              },
            };
          }
          return {
            key,
            content: formatter ? formatter(value) : value,
          };
        }));
      });
      const rentalListingsData = [vacantListed, occupiedListed, vacantUnlisted, occupiedUnlisted];
      return {
        companyRevenueData,
        rentalListingsData,
        companyTableSummaryHeaders,
        companyTableData,
        companyRevenueChartOptions: useDefaultPieChart({
          labels: companyRevenueLabels,
          legend: {
            show: false,
          },
        }),
        rentalListingsChartOptions: useDefaultPieChart({
          labels: rentalListingsData.map((item, index) => `${item} ${rentalListingsLabels[index]}`),
          colors: [TailwindColors.yellow["300"], TailwindColors["light-blue"]["600"], TailwindColors.orange["400"], TailwindColors.blue["400"]],
          chart: {
            type: "donut",
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  total: {
                    showAlways: true,
                    show: true,
                    label: "Total Doors",
                    fontSize: 12,
                    fontWeight: 600,
                  },
                  name: {
                    offsetY: 30,
                  },
                  value: {
                    color: TailwindColors.blue["400"],
                    offsetY: -15,
                    fontWeight: 600,
                    fontSize: 30,
                  },
                },
              },
            },
          },
        }),
      };
    });
    return {
      options,
    };
  },
};
</script>
