<template>
  <div>
    <div class="relative-position">
      <div class="flex">
        <CoreText
          :value="label"
          class="text-sm font-normal text-gray-600"
        />
        <Tooltip
          class="ml-2 flex-start tooltip-icon"
        >
          <slot />
        </Tooltip>
      </div>
      <CoreFieldCurrency
        v-if="currency"
        v-model="formData[props.value]"
        placeholder="Enter amount"
        :is-disabled="props.locked"
        :onkeydown="preventBadCurrencyChars"
      />
      <CoreFieldInteger
        v-if="integer"
        v-model="formData[props.value]"
        placeholder="Enter amount"
        :is-disabled="props.locked"
        :onkeydown="preventBadNonCurrencyChars"
      />
      <CoreFieldTextArea
        v-if="textArea"
        v-model="formData[props.value]"
        placeholder="Enter amount"
        :is-disabled="props.locked"
      />
    </div>
  </div>
</template>

<script>
import { CoreFieldCurrency, CoreFieldInteger, CoreFieldTextArea, CoreText } from "@purepm/core-ui";
import Tooltip from "ui/components/Tooltip.vue";
import { computed } from "vue";
import FormStore from "ui/store/FormStore.js";

export default {
  name: "FRPInputFieldWithTooltip",
  components: {
    CoreFieldCurrency,
    CoreFieldInteger,
    CoreFieldTextArea,
    Tooltip,
    CoreText,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    locked: {
      type: Boolean,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const formStore = FormStore.inject();
    const integer = props.type === "integer";
    const currency = props.type === "currency";
    const textArea = props.type === "textArea";
    const formData = computed({
      get: () => formStore.getFormData.value,
    });
    const preventBadNonCurrencyChars = (event) => {
      if (event.key === "." || event.key === "-" || event.key === "e") event.preventDefault();
    };
    const preventBadCurrencyChars = (event) => {
      if (event.key === "e") event.preventDefault();
    };
    return {
      formData,
      integer,
      currency,
      textArea,
      props,
      preventBadNonCurrencyChars,
      preventBadCurrencyChars,
    };
  },
};
</script>

<style scoped>
.relative-position {
  position: relative;
}
.tooltip-icon {
  margin-top: 2px;
}
</style>
