<template>
  <CoreCard
    class="p-4 h-full max-h-full"
    body-cls="h-full w-full flex flex-col"
  >
    <template #body>
      <div class="flex">
        <CoreText value="By Account" />
        <ChartMaximizer v-if="showMaximize">
          <ChartCategoryRevenue
            :show-maximize="false"
            :chart-options="chartOptions"
            :data="data"
          />
        </ChartMaximizer>
      </div>
      <VueApexCharts
        class="default-chart-pie"
        height="100%"
        :options="chartOptions"
        :series="data"
      />
    </template>
  </CoreCard>
</template>

<script>
import { CoreCard, CoreText } from "@purepm/core-ui";
import VueApexCharts from "vue3-apexcharts";
import ChartMaximizer from "ui/components/ChartMaximizer.vue";

export default {
  name: "ChartCategoryRevenue",
  components: {
    CoreCard,
    CoreText,
    VueApexCharts,
    ChartMaximizer,
  },
  props: {
    showMaximize: {
      type: Boolean,
      default: true,
    },
    chartOptions: {
      type: Object,
      required: true,
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
