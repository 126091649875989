import { currency, number } from "shared/formatters.js";

const proto = Number.prototype;

proto.toCurrency = function(round = true) {
  return currency(this, round);
};

proto.toNumber = function(round = false) {
  return number(this, round);
};

proto.toPercent = function(round = false) {
  return `${this.toNumber(round)}%`;
};
