<template>
  <div class="flex relative h-full bg-indigo-50">
    <section class="flex flex-col flex-1 p-8">
      <div class="flex justify-between mb-2">
        <div>
          <Welcome />
          <CoreText
            v-show="showEdit"
            :value="lastUpdated"
            class="text-xs text-gray-500"
          />
        </div>
        <InputFormFilters />
      </div>
      <InputForm />
    </section>
  </div>
</template>

<script>
import InputFormFilters from "ui/components/InputFormFilters.vue";
import InputForm from "ui/components/InputForm.vue";
import Welcome from "ui/components/Welcome.vue";
import { CoreText } from "@purepm/core-ui";
import FormStore from "ui/store/FormStore.js";
import { computed } from "vue";

export default {
  name: "FinancialForm",
  components: {
    InputFormFilters,
    InputForm,
    Welcome,
    CoreText,
  },
  setup() {
    const formStore = FormStore.inject();
    const showEdit = computed(() => formStore.getShowEdit.value);
    const lastUpdated = computed(() => {
      if (formStore.getUpdatedBy.value === null && formStore.getUpdatedOn.value === null) {
        return "Be the first one to update the form!";
      } else {
        const date = new Date(formStore.getUpdatedOn.value);
        return `Last Updated: ${formStore.getUpdatedBy.value} at ${date.toLocaleDateString("en-US", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        })}, ${date.toLocaleTimeString()}`;
      }
    });
    return {
      formStore,
      lastUpdated,
      showEdit,
    };
  },
};
</script>
