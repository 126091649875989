<template>
  <div
    class="overflow-hidden p-4 mt-4 shadow"
    :class="summary.bgClass"
  >
    <div
      class="text-base font-normal"
      :class="summary.titleTextClass"
    >
      {{ summary.title }}
    </div>
    <div class="flex justify-between items-baseline mt-1 md:block lg:flex">
      <div
        class="flex items-baseline text-2xl font-semibold"
        :class="summary.statColorClass"
      >
        {{ summary.totalDisplay }}
      </div>
      <div>
        <span
          class="pr-2 text-xs font-medium"
          :class="summary.previousPeriodTextColor"
        >
          From {{ summary.previousPeriod }}
        </span>
        <BadgePercentChange :value="summary.totalChange" />
      </div>
    </div>
    <div v-if="summary.doorRevenue">
      <p class="text-xs text-gray-400">
        {{ summary.doorRevenueDisplay }}
      </p>
    </div>
    <slot />
  </div>
</template>

<script>
import BadgePercentChange from "ui/components/BadgePercentChange.vue";

export default {
  name: "SummaryTile",
  components: {
    BadgePercentChange,
  },
  props: {
    /**
     * @type {SummaryTileModel[]}
     */
    summary: {
      type: Object,
      required: true,
    },
  },
};
</script>
