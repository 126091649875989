import { createRouter, createWebHashHistory } from "vue-router";
import Home from "views/Home.vue";
import FinancialForm from "views/FinancialForm.vue";
import MagicWord from "views/MagicWord.vue";
import { authGuard } from "@auth0/auth0-vue";

export default createRouter({
  history: createWebHashHistory(),
  routes: [{
    path: "/",
    name: "Home",
    component: Home,
    beforeEnter: authGuard,
  }, {
    path: "/input-form",
    name: "FinancialForm",
    component: FinancialForm,
    beforeEnter: authGuard,
  }, {
    path: "/magic-word",
    name: "MagicWord",
    component: MagicWord,
    beforeEnter: authGuard,
  },
  ],
});
