import { FIELD_TYPES, Model } from "shared/Model";

/**
 * @property {String} title
 * The title shown, e.g. Total Revenue, Total Doors, etc.
 * @property {Number} total
 * The number being reported, e.g. total revenue value, total doors, etc.
 * @property {String} previousPeriod
 * A default text value of "previous" to indicate that the percentage change is a comparison to the previous period as defined in the filters.
 * @property {String} previousPeriodTextColor
 * A CSS class that is either passed to the model or set as default.
 * @property {Number} totalChange
 * The percentage change since last period.
 * @property {Number} doorRevenue
 * The revenue per do...total revenue divided by total doors.
 * @property {String} bgClass
 * A CSS class that is either passed to the model or set as default
 * @property {Boolean} isCurrency
 * Boolean value toggling the presence of $ and decimal values.  Is set to true when the value of total is totalRevenue and for doorRevenue.  The boolean value is false when total is related to door counts.
 * @property {String} titleTextClass
 * A CSS class that is either passed to the model or set as default.
 * @property {String} statColorClass
 * A CSS class that is either passed to the model or set as default.
 */
export class SummaryTileModel extends Model {
  get fields() {
    return [{
      name: "title",
    }, {
      name: "total",
      type: FIELD_TYPES.DECIMAL,
    }, {
      name: "previousPeriod",
      default: "Previous",
    }, {
      name: "previousPeriodTextColor",
    }, {
      name: "totalChange",
      type: FIELD_TYPES.DECIMAL,
    }, {
      name: "doorRevenue",
      type: FIELD_TYPES.DECIMAL,
    }, {
      name: "bgClass",
      default: "bg-white",
    }, {
      name: "isCurrency",
      type: FIELD_TYPES.BOOLEAN,
    }, {
      name: "titleTextClass",
      default: "text-gray-800",
    }, {
      name: "statColorClass",
    }];
  }
  get totalDisplay() {
    return this.isCurrency ? this.total?.toCurrency() : this.total?.toNumber();
  }
  get doorRevenueDisplay() {
    return this.isCurrency ? `${this.doorRevenue?.toCurrency(false)}/door` : this.doorRevenue;
  }
  get percentDisplay() {
    return this.totalChange?.toPercent();
  }
}


