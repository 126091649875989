import axios from "axios";
import { FIELD_TYPES, Model } from "shared/Model.js";
import { TotalSummaryModel } from "shared/models/TotalSummaryModel.js";
import { CategoryCollection } from "shared/models/CategoryCollection.js";
import { CompanyCollection } from "shared/models/CompanyCollection.js";
import { BASE_API } from "ui/Constants.js";
import { toYearDashMonth } from "../../../shared/utilities.js";

export class RevenueModel extends Model {
  get fields() {
    return [
      {
        name: "summary",
        type: FIELD_TYPES.MODEL,
        model: TotalSummaryModel,
      },
      {
        name: "categories",
        type: FIELD_TYPES.COLLECTION,
        collection: CategoryCollection,
      },
      {
        name: "companies",
        type: FIELD_TYPES.COLLECTION,
        collection: CompanyCollection,
      },
    ];
  }
  static async load(filter) {
    let aggregateType = 0;
    let companies;
    let date;
    if (filter.companies) {
      companies = filter.companies.toString();
    }
    if (filter.aggregateType) {
      aggregateType = filter.aggregateType;
    }
    if (filter.date) {
      date = toYearDashMonth(filter.date);
    }
    const response = await axios({
      method: "get",
      url: `${ BASE_API }/reporting?include=${companies}&aggregateType=${aggregateType}&date=${date}`,
    });
    return new this(response.data);
  }
}
