<template>
  <div class="flex justify-center bg-white max-2-full">
    <div class="fix">
      <img
        class="pointed"
        alt="401 Unauthorized"
        src="/img/401.png"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "MagicWord",
};
</script>

<style>
.fix {
  width:61.8vw;
  height:500px;
}
.pointed {
  display:block;
  margin:auto;
}
.fullscreen {
  background-color:white;
  display:flex;
  align-items:center;
  justify-content: center;
  z-index:9;
  position:fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
}
</style>
