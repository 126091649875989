import { computed, inject, provide, reactive } from "vue";
import { BASE_API } from "ui/Constants.js";
import axios from "axios";
import { toYearDashMonth, getLoadableYearAndMonth } from "shared/utilities.js";

const { loadableMonth, loadableYear } = getLoadableYearAndMonth();

const yearOptions = [];
for (let i = loadableYear; i >= 2018; i--) {
  yearOptions.push({
    value: i,
    label: String(i),
  });
}

const tableLabels = {
  "doorsTotal": "Total Doors",
  "doorsAdded": "Total New Doors",
  "totalAcquiredDoors": "Total Acquired Doors",
  "doorsRemoved": "Total Lost Doors",
  "doorsUnderHOA": "Total Doors Under HOA Management",
  "totalNewDoorsUnderHOA": "Total New Doors Under HOA Management",
  "totalLostDoorsUnderHOA": "Total Lost Doors Under HOA Management",
  "totalAcquiredDoorsUnderHOA": "Total Acquired Doors Under HOA Management",
  "rentCollected": "Rent Collected",
  "rentUnpaidZeroToThirty": "Total Unpaid Rent (0-30)",
  "doorsTransferredIn": "Total Doors Transferred In",
  "doorsTransferredOut": "Total Doors Transferred Out",
  "hoaDoorsTransferredIn": "Total HOA Doors Transferred In",
  "hoaDoorsTransferredOut": "Total HOA Doors Transferred Out",
  "rentUnpaidTotal": "Total Amount of Unpaid Rent",
  "occupiedListed": "Occupied Listed Doors",
  "occupiedUnlisted": "Occupied Unlisted Doors",
  "occupiedTotal": "Total Occupied Doors",
  "percentOccupied": "Occupancy Rate",
  "vacantListed": "Vacant Listed Doors",
  "vacantUnlisted": "Vacant Unlisted Doors",
  "vacantTotal": "Total Vacant Doors",
  "evictionsInProcess": "Evictions in Process",
  "revenueTotal": "Total Revenue",
  "managementFees-billed": "40100: Management Fees Billed",
  "managementFees-paid": "40100: Management Fees Paid",
  "leasingFees-billed": "40200: Leasing/Admin Fees Billed",
  "leasingFees-paid": "40200: Leasing/Admin Fees Paid",
  "tenantFees-paid": "40300: Tenant Fees Paid",
  "tenantFees-billed": "40300: Tenant Fees Billed",
  "maintenanceFees-billed": "40400: Maintenance Fees Billed",
  "maintenanceFees-paid": "40400: Maintenance Fees Paid",
  "applicationFee-billed": "40450: Application Fee Billed",
  "applicationFee-paid": "40450: Application Fee Paid",
  "renewalFees-billed": "40451: Renewal Fees Billed",
  "renewalFees-paid": "40451: Renewal Fees Paid",
  "brokerageFees-billed": "40500: Brokerage Fees Billed",
  "brokerageFees-paid": "40500: Brokerage Fees Paid",
  "commissions-billed": "40525: Commissions Billed",
  "commissions-paid": "40525: Commissions Paid",
  "petInspectionFee-billed": "40550: Pet Inspection Fee Billed",
  "petInspectionFee-paid": "40550: Pet Inspection Fee Paid",
  "miscIncome-billed": "40600: Misc Income Billed",
  "miscIncome-paid": "40600: Misc Income Paid",
  "nsfLateFee-billed": "40650: NSF/Late Fee Billed",
  "nsfLateFee-paid": "40650: NSF/Late Fee Paid",
};

// this is array of the values from tableLabels that will contain numbers that represent dollars.  This array is used in a method below to convert the values to dollar strings for presentation on the dashboard
const tableDollarValues = ["Rent Collected", "Total Unpaid Rent (0-30)", "Total Amount of Unpaid Rent", "Total Revenue", "40100: Management Fees Paid", "40200: Leasing/Admin Fees Paid", "40400: Maintenance Fees Paid", "40451: Renewal Fees Paid", "40500: Brokerage Fees Paid", "40525: Commissions Paid", "40450: Application Fee Paid", "40550: Pet Inspection Fee Paid", "40600: Misc Income Paid", "40650: NSF/Late Fee Paid", "40300: Tenant Fees Paid", "40300: Tenant Fees Billed", "40100: Management Fees Billed", "40200: Leasing/Admin Fees Billed", "40400: Maintenance Fees Billed", "40451: Renewal Fees Billed", "40500: Brokerage Fees Billed", "40525: Commissions Billed", "40450: Application Fee Billed", "40550: Pet Inspection Fee Billed", "40600: Misc Income Billed", "40650: NSF/Late Fee Billed"];

const DashboardStore = {
  init: () => {
    const state = reactive({
      loading: true,
      filterValues: {
        selectedCompanies: [],
        aggregateType: 0, // need to fix this to what it should be
        date: new Date(loadableYear, loadableMonth, 1),
      },
      rows: [],
    });

    // Getters
    const loadingDashboard = computed(() => state.loading);
    const getSelectedCompanies = computed(() => state.filterValues.selectedCompanies);
    const getAggregateType = computed(() => state.filterValues.aggregateType);
    const getTable = computed(() => state.table);
    const getRows = computed(() => state.rows);
    const getDate = computed(() => state.filterValues.date.getFullYear());
    const getLoadableMonth = loadableMonth;
    const getLoadableYear = loadableYear;

    // Mutations
    const updateSelectedCompanies = (companies) => {
      state.filterValues.selectedCompanies = companies;
    };
    const updateAggregateType = (aggregateType) => {
      state.filterValues.aggregateType = aggregateType;
    };
    const updateDate = (date) => {
      state.filterValues.date = new Date(date);
    };
    const updateTable = (table) => {
      state.rows = [];
      mapTable(table);
    };

    const mapTable = (table) => {
      const mapping = {};
      const rows = state.rows;
      table.forEach((element) => {
        if (element.doorsTotal) {
          element.percentOccupied = element.occupiedTotal / element.doorsTotal;
        } else element.percentOccupied = "-";
        Object.keys(element).forEach((key) => {
          let mappingKey = mapping[key];
          if (key !== "month") {
            if (mappingKey) {
              mappingKey[element.month + 1] = {
                key: element.month,
                content: element[key],
              };
            } else {
              mapping[key] = [];
              mappingKey = mapping[key];
              mappingKey[0] = {
                key: "category",
                content: tableLabels[key],
              };
              mappingKey[element.month + 1] = {
                key: element.month,
                content: element[key],
              };
            }
          }
        });
      });
      let indexOfTotalOccupiedUnits;
      let indexOfPercentOccupiedUnits;
      Object.keys(mapping).forEach((key) => {
        // check if a content value is one that should be converted to a dollar string in the table
        if (tableDollarValues.includes(mapping[key][0].content)) {
          // if it is, add a dollar sign and commas
          for (let i = 1; i <= 12; i++) {
            mapping[key][i].content = "$" + mapping[key][i].content.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
          }
          // if it is not, just add commas
        } else {
          for (let i = 1; i <= 12; i++) {
            if (mapping[key][0].content === "Occupancy Rate") {
              mapping[key][i].content = mapping[key][i].content.toLocaleString("en-US", {
                style: "percent",
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
              });
            } else {
              mapping[key][i].content = mapping[key][i].content.toLocaleString("en-US");
            }
          }
        }
        rows.push(mapping[key]);
        if (mapping[key][0].content === "Total Occupied Doors") {
          indexOfTotalOccupiedUnits = rows.indexOf(mapping[key]);
        }
        if (mapping[key][0].content === "Occupancy Rate") {
          indexOfPercentOccupiedUnits = rows.indexOf(mapping[key]);
        }
      });
      const percentOccupiedRow = rows.splice(indexOfPercentOccupiedUnits, 1);
      rows.splice(indexOfTotalOccupiedUnits + 1, 0, percentOccupiedRow[0]);
    };


    // Actions
    const getTableData = async () => {
      state.loading = true;
      const types = ["table"];
      const date = toYearDashMonth(state.filterValues.date);
      const companies = state.filterValues.selectedCompanies;
      const aggregateType = state.filterValues.aggregateType;
      const response = await axios.get(`${ BASE_API }/reporting?include=${companies}&aggregateType=${aggregateType}&date=${date}&types=${types}`);
      state.loading = false;
      updateTable(response.data);
    };

    provide("getSelectedCompanies", getSelectedCompanies);
    provide("getAggregateType", getAggregateType);
    provide("getTable", getTable);
    provide("updateSelectedCompanies", updateSelectedCompanies);
    provide("updateAggregateType", updateAggregateType);
    provide("updateDate", updateDate);
    provide("updateTable", updateTable);
    provide("getTableData", getTableData);
    provide("getRows", getRows);
    provide("getDate", getDate);
    provide("loadingDashboard", loadingDashboard);
    provide("getLoadableMonth", getLoadableMonth);
    provide("getLoadableYear", getLoadableYear);
  },

  inject: () => ({
    getSelectedCompanies: inject("getSelectedCompanies"),
    getAggregateType: inject("getAggregateType"),
    getTable: inject("getTable"),
    updateSelectedCompanies: inject("updateSelectedCompanies"),
    updateAggregateType: inject("updateAggregateType"),
    updateDate: inject("updateDate"),
    updateTable: inject("updateTable"),
    getTableData: inject("getTableData"),
    getRows: inject("getRows"),
    getDate: inject("getDate"),
    loadingDashboard: inject("loadingDashboard"),
    getLoadableMonth: inject("getLoadableMonth"),
    getLoadableYear: inject("getLoadableYear"),
  }),
};

export default DashboardStore;
