<template>
  <h1 class="text-4xl font-normal text-indigo-800">
    Welcome
  </h1>
</template>

<script>
export default {
  name: "Welcome",
};
</script>
