import { SummaryModel } from "shared/models/SummaryModel.js";
import { FIELD_TYPES } from "shared/Model.js";
import { CategoryCollection } from "shared/models/CategoryCollection.js";

/**
 * @property {Number} revenueTotal
 * @property {Number} doorsTotal
 * @property {Number} doorsAdded
 * @property {Number} doorsRemoved
 * @property {Date} date
 * @property {CategoryModel[]} categories
 */
export class RevenueHistoryModel extends SummaryModel {
  get fields() {
    const fields = super.fields;
    return fields.concat([{
      name: "date",
      type: FIELD_TYPES.DATE,
    }, {
      name: "categories",
      type: FIELD_TYPES.COLLECTION,
      collection: CategoryCollection,
    }]);
  }
}
